import { getFieldEditSchema } from '../../edit/field/fieldEditSchema';
import { TFunction } from 'i18next';
import { GetCreateFieldOptionsQuery } from '~/__generated__/graphql';

export const getFieldCreateSchema = ({
  t,
  createFieldOptions,
}: {
  t: TFunction;
  createFieldOptions?: GetCreateFieldOptionsQuery | undefined;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-field-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createField',
            title: t('fields::create-field'),
            fields: getFieldEditSchema({
              t,
              countries: createFieldOptions?.countries,
              customers: createFieldOptions?.customers,
            }).fields,
          },
        ],
      },
    ],
  };
};
