import {
  componentTypes,
  dataTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetChannelQuery, GetCreateChannelOptionsQuery } from '~/__generated__/graphql';

interface GetImdInterfaceConfigurationEditSchemaProps {
  t: TFunction;
  poweringDevices?: GetCreateChannelOptionsQuery['poweringDevices'];
  relayConfigurations?: GetCreateChannelOptionsQuery['relayConfigurations'];
  tripAlarmRelays?: GetCreateChannelOptionsQuery['tripAlarmRelays'];
  interfaceCards?: GetCreateChannelOptionsQuery['interfaceCards'];
  channel?: GetChannelQuery['channel'];
  editing?: boolean;
}

export function getImdInterfaceConfigurationEditSchema({
  t,
  poweringDevices,
  relayConfigurations,
  tripAlarmRelays,
  interfaceCards,
  channel,
}: GetImdInterfaceConfigurationEditSchemaProps) {
  return {
    fields: [
      {
        name: 'alarm1Value',
        label: t('customer-asset-forms::alarm1-value'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        initialValue: channel?.alarm1Value,
        suffix: 'Ω',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::alarm1-value-message'),
          },
        ],
      },
      {
        name: 'alarm2Value',
        label: t('customer-asset-forms::alarm2-value'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        initialValue: channel?.alarm2Value,
        suffix: 'Ω',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::alarm2-value-message'),
          },
        ],
      },
      {
        name: 'tripAlarmRelayId',
        label: t('customer-asset-forms::trip-alarm-relay'),
        isClearable: true,
        component: componentTypes.SELECT,
        initialValue: channel?.tripAlarmRelay,
        options: tripAlarmRelays,
      },
      {
        name: 'relayConfigurationId',
        label: t('customer-asset-forms::relay-configuration'),
        isClearable: true,
        component: componentTypes.SELECT,
        initialValue: channel?.relayConfiguration,
        options: relayConfigurations,
      },
      {
        name: 'couplerUsed',
        label: t('customer-asset-forms::coupler-used'),
        component: componentTypes.TEXT_FIELD,
        initialValue: channel?.couplerUsed,
      },
      {
        name: 'interfaceCardId',
        label: t('customer-asset-forms::interface-card'),
        helperText: t('customer-asset-forms::interface-card-helper'),
        isClearable: true,
        component: componentTypes.SELECT,
        initialValue: channel?.interfaceCard,
        options: interfaceCards,
      },
      {
        name: 'poweringDeviceId',
        label: t('customer-asset-forms::powering-device'),
        isClearable: true,
        component: componentTypes.SELECT,
        initialValue: channel?.poweringDevice,
        options: poweringDevices,
      },
      {
        name: 'negativeImpactOnRebootNotes',
        label: t('customer-asset-forms::negative-impact-on-reboot-notes'),
        helperText: t('customer-asset-forms::negative-impact-on-reboot-notes-helper'),
        component: componentTypes.TEXTAREA,
        initialValue: channel?.negativeImpactOnRebootNotes,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
}
