import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import { NegatableNumberListFilters } from '~/components/DataGrid/filters/types';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { useTranslation } from 'react-i18next';
import { VLifeStatusSelect } from '../../inputs/VLifeStatusSelect';

const defaultValues: NegatableNumberListFilters = {
  isNegated: false,
  comparatorValues: [],
};

export const VLifeStatusListInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } = useFilterDebounceAndApply<NegatableNumberListFilters>(
    item,
    applyValue,
    defaultValues
  );

  const handleStatusFilterChange = (event: SelectChangeEvent<number[]>) => {
    const { value } = event.target;
    const { comparatorValues } = values;

    console.log('comparatorValues', comparatorValues, value);

    if (comparatorValues && !comparatorValues.includes(Number(value))) {
      updateFilterValue({ comparatorValues: [...comparatorValues, Number(value)] });
    } else if (comparatorValues && comparatorValues.includes(Number(value))) {
      updateFilterValue({
        comparatorValues: comparatorValues.filter((status) => status !== Number(value)),
      });
    }
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <VLifeStatusSelect
        name='statuses'
        placeholder={t('filter-inputs::statuses')}
        value={values.comparatorValues}
        onChange={handleStatusFilterChange}
        type='number'
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
