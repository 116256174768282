import { Schema, componentTypes, dataTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { extraComponents } from '~/components/Form/extraComponents';
import { GetChannelQuery } from '~/__generated__/graphql';
import {} from '../../tools';

interface GetSystemInformationEditSchemaProps {
  t: TFunction;
  channel?: GetChannelQuery['channel'];
}

export function getSystemInformationViewSchema({
  t,
  channel,
}: GetSystemInformationEditSchemaProps): Schema {
  return {
    fields: [
      {
        name: 'platform',
        label: t('customer-asset-forms::platform'),
        component: extraComponents.SUBHEADING,
      },
      {
        name: 'platformName',
        label: t('customer-asset-forms::platform-name'),
        component: componentTypes.SELECT,
        initialValue: channel?.platform,
        isRequiredForReporting: true,
      },
      {
        name: 'waterDepth',
        label: t('customer-asset-forms::water-depth'),
        component: componentTypes.PLAIN_TEXT,
        suffix: 'm',
        initialValue: channel?.platform?.waterDepth,
      },
      {
        name: 'pcs',
        label: t('customer-asset-forms::pcs'),
        component: extraComponents.SUBHEADING,
      },
      {
        name: 'pcsManufacturer',
        label: t('customer-asset-forms::pcs-manufacturer'),
        component: componentTypes.SELECT,
        initialValue: channel?.productionControlSystem?.pcsManufacturer,
      },
      {
        name: 'pcsApproxInstallationDate',
        label: t('customer-asset-forms::pcs-approx-installation-date'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.NUMBER,
        initialValue: channel?.productionControlSystem?.pcsApproxInstallationDate,
      },
      {
        name: 'epuManufacturer',
        label: t('customer-asset-forms::epu-manufacturer'),
        component: componentTypes.SELECT,
        initialValue: channel?.productionControlSystem?.epuManufacturer,
      },
      {
        name: 'epuApproxInstallationDate',
        label: t('customer-asset-forms::epu-approx-installation-date'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.NUMBER,
        initialValue: channel?.productionControlSystem?.epuApproxInstallationDate,
      },
      {
        name: 'frequency',
        label: t('customer-asset-forms::asset-frequency'),
        component: componentTypes.PLAIN_TEXT,
        suffix: 'Hz',
        initialValue: channel?.productionControlSystem?.frequency,
      },
      {
        name: 'epuMaximumVoltage',
        label: t('customer-asset-forms::epu-maximum-voltage'),
        component: componentTypes.PLAIN_TEXT,
        suffix: 'V',
        initialValue: channel?.productionControlSystem?.maximumOperatingVoltage,
      },
      {
        name: 'assetElectricalDistributionTypeId',
        label: t('customer-asset-forms::asset-electrical-distribution-type'),
        component: componentTypes.SELECT,
        initialValue: channel?.productionControlSystem?.electricalDistributionType,
      },
      {
        name: 'electricalTopologyId',
        label: t('customer-asset-forms::electrical-topology'),
        component: componentTypes.SELECT,
        initialValue: channel?.productionControlSystem?.electricalTopology,
      },
      {
        name: 'umbilical',
        label: t('customer-asset-forms::umbilical'),
        component: extraComponents.SUBHEADING,
      },
      {
        name: 'manufacturer',
        label: t('customer-asset-forms::umbilical-manufacturer'),
        component: componentTypes.SELECT,
        initialValue: channel?.umbilical?.manufacturer,
      },
      {
        name: 'type',
        label: t('customer-asset-forms::umbilical-type'),
        component: componentTypes.SELECT,
        initialValue: channel?.umbilical?.materialType,
      },
      {
        name: 'approxInstallationDate',
        label: t('customer-asset-forms::approx-umbilical-installation-date'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: channel?.umbilical?.approxInstallationDate,
      },
      {
        name: 'length',
        label: t('customer-asset-forms::umbilical-length'),
        component: componentTypes.PLAIN_TEXT,
        suffix: 'km',
        initialValue: channel?.umbilical?.length,
      },
    ],
  };
}
