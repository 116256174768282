import { kebabCase } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pill } from '~/components/Pill';
import { RowDataType, Table } from '~/components/Table/Table';
import { useGetDashboardDetailsByMonth } from '~/queries/reports';
import { Path } from '~/routes/paths';
import { formatDate } from '~/utils/date-utils';
import { Dialog } from '~/components/Dialog';
import { getEndDateString, getStartDateString } from './dashboardTools';
import { GetDashboardDetailsByMonthQuery } from '~/__generated__/graphql';
import { DateTime } from 'luxon';
import { CellLink } from '~/components/Table/components/CellLink';
import { statusColors } from '~/common/constants';

// t('trial');
// t('paid');
// t('free-of-charge');

export const ChannelDetailsDialog: React.FC<{
  open: boolean;
  month: string;
  onClose: () => void;
  onExport: (
    month: string,
    data: NonNullable<GetDashboardDetailsByMonthQuery['reports']>['vlifeLicenceHistoryDetails']
  ) => Promise<void>;
}> = ({ open, month, onClose, onExport }) => {
  const { t } = useTranslation('dashboard');
  const { data, loading } = useGetDashboardDetailsByMonth(month);

  const startDate = DateTime.fromISO(month, { zone: 'UTC' });
  const endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
  const period = `${formatDate(month)} – ${formatDate(endDate.toISODate())}`;

  async function onConfirm() {
    if (data?.reports.vlifeLicenceHistoryDetails) {
      await onExport(month, data?.reports.vlifeLicenceHistoryDetails);
    }
  }

  const tabularData: RowDataType[] | undefined = useMemo(
    () =>
      data?.reports.vlifeLicenceHistoryDetails.map((row, i: number) => {
        const {
          vLifeConfig: { startDate, expiryDateCommToCustomer, channel, vlifeLicence },
          installedViperAssets,
        } = row;

        return {
          _id: i + 1,
          [t('customer')]: channel.customer.name,
          [t('field')]: channel.field.name,
          [t('channel')]: channel.name,
          [t('beginning-date')]: {
            label: getStartDateString(month, startDate, t('ongoing')),
            value: startDate,
          },
          [t('end-date')]: {
            label: getEndDateString(
              endDate.toISODate(),
              expiryDateCommToCustomer,
              t('ongoing')
            ),
            value: expiryDateCommToCustomer,
          },
          [t('channel-number')]: {
            label: (
              <CellLink
                path={Path.CUSTOMER_ASSET}
                param={{ id: channel.id }}
                content={channel.id}
              />
            ),
            value: Number(channel.id),
          },
          [t('viper-assets')]: {
            label:
              installedViperAssets.length > 0
                ? installedViperAssets?.map((asset) => (
                    <CellLink
                      key={asset.id}
                      path={Path.VIPER_ASSET}
                      param={{ id: asset.id }}
                      content={asset.serialNumber}
                      sx={{
                        '& + &::before': { content: '","', mr: 0.5 },
                      }}
                    />
                  ))
                : undefined,
            textValue: installedViperAssets[0]?.serialNumber,
            value:
              installedViperAssets && installedViperAssets.length > 0
                ? installedViperAssets[0]?.serialNumber
                : '',
          },
          [t('licence-type')]: {
            label: (
              <Pill
                label={t(kebabCase(vlifeLicence.vlifeLicenceType.name))}
                iconColor={statusColors[Number(vlifeLicence.vlifeLicenceType.id)]}
              />
            ),
            textValue: vlifeLicence.vlifeLicenceType.name,
            value: vlifeLicence.vlifeLicenceType.name,
          },
        };
      }),
    [data?.reports.vlifeLicenceHistoryDetails, endDate, month, t]
  );

  return (
    <Dialog
      fullWidth
      tight
      maxWidth='lg'
      onClose={onClose}
      open={open}
      content={<Table data={tabularData ?? []} muted embedded loading={loading} searchable />}
      title={`${t('channel-details')} ${period}`}
      cancelText={t('viper::cancel')}
      disabled={loading}
      onConfirm={onConfirm}
      confirmText={t('download-xlsx')}
    />
  );
};
