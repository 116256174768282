import { GridColDef } from '@mui/x-data-grid-pro';
import { get } from 'lodash';
import { formatDate } from '~/utils/date-utils';

export function dataGridRowMapper<T>(
  columns: GridColDef[]
): (data: Record<string, unknown>) => T {
  return (data: Record<string, unknown>) => {
    return columns.reduce((acc: T, column): T => {
      const field = column.field as keyof T;
      const value = get(data, field);
      return {
        ...acc,
        [field]: value,
      };
    }, {} as T);
  };
}

export const formatDateRange = (startDate?: string, endDate?: string): string => {
  if (!startDate && !endDate) {
    return '-';
  }

  if (!startDate) {
    return `Start - ${formatDate(endDate)}`;
  }

  if (!endDate) {
    return `${formatDate(startDate)} Onward`;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};
