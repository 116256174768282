import {
  VLifeConfigWithStatus,
  VLifeHistoricalStatus,
  VLifeStatus,
} from '~/pages/CustomerAssets/types';
import { VLifeConfig } from '~/__generated__/graphql';
import { DateTime } from 'luxon';
import { getVLifeConfigStatus, sortByStartDate } from '~/pages/CustomerAssets/utils';
import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { getVLifeConfigsWithStatus } from '~/pages/CustomerAssets/custom-row-fields/getVLifeConfigsWithStatus';

const getDifferenceInHours = (startDate: string | Date, endDate: string | Date): number => {
  const endDateDateTime = DateTime.fromISO(new Date(endDate).toISOString());
  const startDateDateTime = DateTime.fromISO(new Date(startDate).toISOString());
  return endDateDateTime.diff(startDateDateTime, 'hours').hours;
};

type PopulateVLifeLicencesProps = {
  upcomingVLifeConfig?: Omit<VLifeConfig, 'channel'> | null;
  mostRecentVLifeConfig?: Omit<VLifeConfig, 'channel'> | null;
  historicalVLifeConfigs?: (Omit<VLifeConfig, 'channel'> | null)[];
};

const addDays = (date: Date | undefined, daysToAdd: number): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
};

export const generateVLifeTimeline = (
  channel: PopulateVLifeLicencesProps
): VLifeStatusWindow[] => {
  if (!channel) {
    return [];
  }

  // Get a sorted array of the V-LIFE configs, sorted by date descending
  const allVLifeLicences = getVLifeConfigsWithStatus(channel).sort(sortByStartDate).reverse();

  // Iterate through all licences and populate records where gaps of > 1 day exist
  const statusWindows = allVLifeLicences.reduce(
    (statusWindows: VLifeStatusWindow[], currentConfig, index, vLifeLicences) => {
      // nextConfig is the next config in date order, but the previous one in the list
      const nextConfig: VLifeConfigWithStatus | undefined = vLifeLicences[index - 1];
      const nextConfigStartDate = nextConfig?.startDate
        ? new Date(nextConfig.startDate)
        : undefined;

      const currentStatus = getVLifeConfigStatus({
        mostRecentVLifeConfig: currentConfig,
        upcomingVLifeConfig: nextConfig,
      });
      const currentConfigStartDate = new Date(currentConfig.startDate);
      const currentConfigEndDate = new Date(currentConfig.expiryDateCommToCustomer);

      const inactiveStatuses = [
        VLifeStatus.INACTIVE,
        VLifeStatus.SUSPENDED,
        VLifeStatus.DEACTIVATED,
      ];

      // If the current status is inactive, use the inactive status for the gap, else never set up
      const gapStatus = inactiveStatuses.includes(currentStatus)
        ? currentStatus
        : VLifeStatus.NEVER_SET_UP;
      const gapStartDate = new Date(
        DateTime.fromJSDate(currentConfigEndDate).plus({ days: 1 }).toISODate()
      );

      // Fill gap between current licence and next, if necessary
      if (!nextConfig && currentConfig.status !== VLifeHistoricalStatus.UPCOMING) {
        // There is no next licence AND current licence isn't upcoming,
        // populate from end date of latest, with no end date (lasts forever)
        if (
          !(DateTime.fromJSDate(gapStartDate) >= DateTime.now() && statusWindows.length === 0)
        ) {
          // Only push a future gap into the list if there's already something in the list
          statusWindows.push({
            startDate: gapStartDate,
            status: gapStatus,
            config: currentConfig,
          });
        }
      } else if (
        nextConfigStartDate &&
        getDifferenceInHours(currentConfig.expiryDateCommToCustomer, nextConfigStartDate) > 24
      ) {
        // There is a next licence and the gap is larger than 1 day
        // populate gap status window between end of current and start of next

        // Gap start date should be the day after the previous licence ended,
        // and the end date should be the day before the next licence starts
        const gapEndDate = new Date(
          DateTime.fromJSDate(nextConfigStartDate).minus({ days: 1 }).toISODate()
        );

        statusWindows.push({
          startDate: gapStartDate,
          endDate: gapEndDate,
          status: gapStatus,
          config: currentConfig,
        });
      }

      // Add a row for the active V-Life licence type
      statusWindows.push({
        startDate: currentConfigStartDate,
        endDate: currentConfigEndDate,
        status: Number(currentConfig.vlifeLicence.vlifeLicenceType.id),
        config: currentConfig,
      });

      return statusWindows;
    },
    []
  );

  // Create an initial VLifeStatusWindow up until the start date of the first config, with an unlicenced type
  // -- this is useful for the filters that derive gains (Nothing -> PAID) within a query window
  if (statusWindows.length > 0) {
    const sortedStatusWindows = [...statusWindows].reverse();
    const firstStartDate = sortedStatusWindows[0]?.startDate;
    statusWindows.push({
      endDate: addDays(firstStartDate, -1),
      status: VLifeStatus.NEVER_SET_UP,
    });
  }

  // Apply gain / attrition to windows, based off status changes between previous and current
  const statusWindowsWithGainsAndAttrition = statusWindows.map(
    (current, index, arr): VLifeStatusWindow => {
      // Order is currently descending, so previous status is next in array
      const previous = arr[index + 1];
      const next = arr[index - 1];

      if (previous || next) {
        // Attrition
        const attrition =
          next && current.status === VLifeStatus.PAID && next.status !== VLifeStatus.PAID;
        const gain =
          previous &&
          previous.status !== VLifeStatus.PAID &&
          current.status === VLifeStatus.PAID;

        return {
          attrition,
          gain,
          ...current,
        };
      }
      return current;
    }
  );

  // Always return this array in date descending order
  statusWindowsWithGainsAndAttrition.sort(sortByStartDate);
  return statusWindowsWithGainsAndAttrition.reverse();
};
