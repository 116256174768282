import {
  componentTypes,
  Schema,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { DateTime } from 'luxon';
import { extraValidatorTypes } from '~/components/Form/validators';
import { formatPerson } from '~/utils/format-person';
import { extraComponents } from '~/components/Form/extraComponents';
import { LifecycleEventType } from '~/__generated__/graphql';
import { GetVLifeEditSchemaProps } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/types';
import { useTranslation } from 'react-i18next';

export const useLifecycleInstallationEditSchema = ({
  lifecycleEvent,
  installationTypes,
  people,
  customers,
  channels,
  editing,
  creating,
}: GetVLifeEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'dateOfLifecycleEvent',
        label: t('lifecycle/installation/date'),
        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: lifecycleEvent?.dateOfLifecycleEvent ?? DateTime.now().toISODate(),
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
      },
      {
        name: 'personId',
        isSearchable: true,
        sortAlphabetical: true,
        isClearable: true,
        label: t('lifecycle/installation/person'),
        component: componentTypes.SELECT,
        options: people?.map((person) => ({
          id: person.id,
          name: formatPerson(person),
        })),
        initialValue: {
          id: lifecycleEvent?.person?.id,
          name: formatPerson(lifecycleEvent?.person),
        },
      },
      {
        name: 'installationTypeId',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/installation/type'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: installationTypes,
        initialValue: lifecycleEvent?.installationType,
      },
      {
        name: 'notes',
        label: t('lifecycle/installation/notes'),
        component: componentTypes.TEXTAREA,
        initialValue: lifecycleEvent?.notes,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'associatedChannel',
        label: t('lifecycle/channel/associated-channel'),
        component: extraComponents.SUBHEADING,
      },
      {
        name: 'customerName',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/channel/customer-name'),
        component: editing && !creating ? componentTypes.PLAIN_TEXT : componentTypes.SELECT,
        isRequired: true,
        options: customers,
        initialValue:
          editing && !creating
            ? lifecycleEvent?.channel?.customer?.name
            : lifecycleEvent?.channel?.customer,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'fieldName',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/channel/field-name'),
        component: editing && !creating ? componentTypes.PLAIN_TEXT : componentTypes.SELECT,
        isRequired: true,
        options: [],

        initialValue:
          editing && !creating
            ? lifecycleEvent?.channel?.field?.name
            : lifecycleEvent?.channel?.field,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        resolveProps: (_props, _field, { getState }) => {
          if (editing && !creating) {
            return {};
          }
          const selectedCustomer = getState().values.customerName;
          const channelsByCustomer = channels?.filter(
            ({ customer, latestLifecycleEvent }) =>
              customer?.id === selectedCustomer &&
              (latestLifecycleEvent === null ||
                latestLifecycleEvent?.eventType === LifecycleEventType.Uninstallation)
          );
          return {
            disabled: !selectedCustomer,
            options: channelsByCustomer?.map(({ field }) => field),
          };
        },
      },
      {
        name: 'channelId',
        isSearchable: true,
        sortAlphabetical: true,
        label: t('lifecycle/channel/channel-name'),
        component: editing && !creating ? componentTypes.PLAIN_TEXT : componentTypes.SELECT,
        isRequired: true,
        options: [],
        initialValue:
          editing && !creating ? lifecycleEvent?.channel?.name : lifecycleEvent?.channel,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        resolveProps: (_props, _field, { getState }) => {
          if (editing && !creating) {
            return {};
          }
          const selectedField = getState().values.fieldName;
          const channelsByField = channels?.filter(
            ({ field, latestLifecycleEvent }) =>
              field?.id === selectedField &&
              (latestLifecycleEvent === null ||
                latestLifecycleEvent?.eventType === LifecycleEventType.Uninstallation)
          );
          return {
            disabled: !selectedField,
            options: channelsByField,
          };
        },
      },
    ],
  };
};
