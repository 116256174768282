import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateField, useGetCreateFieldOptions } from '~/queries/channel';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getFieldCreateSchema } from '../../schemas/create/field/fieldCreateSchema';

export const CreateFieldDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation('fields');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-field')}
      errorMessage={t(`error-creating`)}
      successMessage={(field) => t('field-created-successfully', { field })}
      useGetOptions={useGetCreateFieldOptions}
      useMutate={useCreateField}
      getSchema={({ t, options }) =>
        getFieldCreateSchema({ t, createFieldOptions: options }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            fieldInput: {
              name: values.name as string,
              countryId: values.countryId as string,
              defaultCustomerId: values.defaultCustomerId as string,
              defaultOperatorId: values.defaultOperatorId as string,
            },
          },
        });
        return values.name as string;
      }}
    />
  );
};
