import React from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ReportDetailComment } from '~/__generated__/graphql';
import { formatDateTime } from '~/utils/date-utils';

export const Comments: React.FC = () => {
  const { t } = useTranslation();
  const { getState } = useFormApi();
  const { comments } = getState().values;

  if (!comments || comments.length === 0) {
    return <Typography>{t('no-comments')}</Typography>;
  }
  return (
    <Box>
      {comments.map((comment: ReportDetailComment) => {
        return (
          <Box key={comment.id} sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 14 }}>
              <Trans
                i18nKey='commented-on'
                t={t}
                components={{ strong: <strong /> }}
                values={{
                  name: comment.commenter.name,
                  date: formatDateTime(comment.date),
                }}
              />
            </Typography>
            <Typography sx={{ fontSize: 14 }}>{comment.comment}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
