import { useMemo } from 'react';
import { WarningLink } from '~/components/WarningLink';
import { formatDate } from '~/utils/date-utils';
import { useTranslation } from 'react-i18next';
import { WarningListResponse } from '~/pages/warnings/types';
import { useGetChannelVLifeConfigs } from '~/queries/channel';

/*
  This hook returns warnings if no VLife Licenses are present in the report window
 */
export const useGetMissingVLifeLicenseWarnings = (
  channelId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined
): WarningListResponse => {
  const { t } = useTranslation('warnings');
  const { data, loading } = useGetChannelVLifeConfigs(channelId);

  const noVLifeLicenseOverlap: boolean = useMemo(() => {
    // Don't return warning if missing data, as it may still be loading
    if (!startDate || !endDate || !data?.channel) {
      return false;
    }

    const { upcomingVLifeConfig, mostRecentVLifeConfig, historicalVLifeConfigs } =
      data.channel;
    const vLifeConfigs = [
      upcomingVLifeConfig,
      mostRecentVLifeConfig,
      ...historicalVLifeConfigs,
    ];

    const reportStartDate = new Date(startDate);
    const reportEndDate = new Date(endDate);

    const vLifeLicenseOverlaps = vLifeConfigs.find((vLifeConfig) => {
      if (!vLifeConfig) {
        return false;
      }

      const vLifeStartDate = new Date(vLifeConfig?.startDate);
      const vLifeEndDate = new Date(vLifeConfig.expiryDateCommToCustomer);

      // V-Life license falls entirely inside report range - good
      if (reportStartDate <= vLifeStartDate && vLifeEndDate <= reportEndDate) {
        return true;
      }

      // Report range falls inside V-Life license range - good
      if (vLifeStartDate <= reportStartDate && reportEndDate <= vLifeEndDate) {
        return true;
      }

      // V-Life license starts or ends within report range - good
      if (
        (reportStartDate <= vLifeStartDate && vLifeStartDate <= reportEndDate) ||
        (reportStartDate <= vLifeEndDate && vLifeEndDate <= reportEndDate)
      ) {
        return true;
      }

      // No overlap found
      return false;
    });

    return !vLifeLicenseOverlaps;
  }, [data?.channel, endDate, startDate]);

  const warnings = useMemo<WarningLink[]>((): WarningLink[] => {
    const warnings: WarningLink[] = [];

    if (loading) {
      return [];
    }

    if (noVLifeLicenseOverlap) {
      warnings.push({
        id: `missing-v-life-licence-${channelId}`,
        warningMessage: t('missing-v-life-licence', {
          start: formatDate(startDate),
          end: formatDate(endDate),
        }),
      });
    }
    return warnings;
  }, [noVLifeLicenseOverlap, loading, channelId, endDate, startDate, t]);

  return {
    warnings,
    loading,
  };
};
