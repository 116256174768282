import React from 'react';

import { Table } from '~/components/Table/Table';
import { Box, Button, Typography } from '@mui/material';
import { UpdateFirmwareLogDialog } from '../../create-edit-dialogs/FirmwreLog/UpdateFirmwareLogDialog';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { useFirmwareLog } from './useFirmwareLog';
import { ACTION_EDIT } from '~/components/ActionMenu';
import { CreateFirmwareLogDialog } from '../../create-edit-dialogs/FirmwreLog/CreateFirmwareLogDialog';
import { useTranslation } from 'react-i18next';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

export const FirmwareLog: React.FC<{
  viperAsset: GetViperAssetQuery['viperAsset'];
}> = ({ viperAsset }) => {
  const { t } = useTranslation('viper-asset');
  const {
    handleFirmwareLogActionClick,
    showFirmwareLogDialog,
    setShowFirmwareLogDialog,
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    firmwareLogs,
    firmwareLogsDetails,
    loading,
    id,
  } = useFirmwareLog(viperAsset);

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('firmware-logs')}</Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setShowCreateFirmwareLogDialog(true)}
        >
          {t('update-firmware')}
        </Button>
      </Box>
      <Table
        data={firmwareLogs}
        omittedKeys={['_id', '_actions']}
        translationKey={'viper-asset'}
        nestedData={firmwareLogsDetails}
        actionMenu={actionMenu}
        onActionClick={handleFirmwareLogActionClick}
        defaultOrderBy={t('viper-asset::firmware/date') ?? 'date'}
      />
      <UpdateFirmwareLogDialog
        open={!!showFirmwareLogDialog}
        onClose={() => {
          setShowFirmwareLogDialog(undefined);
        }}
        id={id}
        firmwareLogs={viperAsset?.firmwareUpdates}
        editId={showFirmwareLogDialog}
      />
      <CreateFirmwareLogDialog
        open={showCreateFirmwareLogDialog}
        onClose={() => {
          setShowCreateFirmwareLogDialog(false);
        }}
        id={id}
        firmwareLogs={viperAsset?.firmwareUpdates}
      />
    </>
  );
};
