import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { ChannelWithVLifeTimeline } from '~/pages/Dashboard/useGetChannelsWithVLifeTimelines';

export const flattenVLifeTimelineRows = (
  allRows: LicenceInsightsRow[],
  channelWithTimeline: ChannelWithVLifeTimeline
) => {
  const { vLifeTimeline, ...channelDetails } = channelWithTimeline;
  const channelInsightsRows: LicenceInsightsRow[] = vLifeTimeline.map(
    (statusWindow): LicenceInsightsRow => ({
      id: Number(statusWindow.config?.id ?? -1),
      ...statusWindow,
      ...channelDetails,
    })
  );
  return [...allRows, ...channelInsightsRows];
};
