import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetChannelQuery } from '~/__generated__/graphql';
import { formatDate } from '~/utils/date-utils';
import { formatPerson } from '~/utils/format-person';

export function useCustomerAssetMaintenanceLog(
  maintenanceLogs: NonNullable<GetChannelQuery['channel']>['maintenanceLogs'] | undefined
) {
  const { t } = useTranslation('customer-asset');
  const [showMaintenanceLogDialog, setShowMaintenanceLogDialog] = useState<
    string | undefined
  >();
  const [showCreateMaintenanceLogDialog, setShowCreateMaintenanceLogDialog] =
    useState<boolean>(false);

  const tableData = useMemo(() => {
    return (
      maintenanceLogs?.map((log) => ({
        _id: log.id,
        [t('maintenance/start-date')]: formatDate(log.maintenanceStartDate),
        [t('maintenance/end-date')]: {
          label: formatDate(log.maintenanceEndDate),
          value: log.maintenanceEndDate,
        },
        [t('maintenance/type')]: log.maintenanceType.name,
        [t('maintenance/person')]: formatPerson(log.person),
        [t('maintenance/company')]: log.person?.company?.name,
      })) ?? []
    );
  }, [t, maintenanceLogs]);

  const tableDetails = useMemo(() => {
    return (
      maintenanceLogs?.map((log) => ({
        _id: log.id,
        [t('maintenance/outcome')]: log.outcomeOfMaintenance,
        [t('maintenance/affected-viper-assets')]: log.affectedViperAssets
          .map((asset) => asset.serialNumber)
          .join(', '),
      })) ?? []
    );
  }, [t, maintenanceLogs]);

  function handleMaintenanceLogActionClick(
    menuItemId: string,
    rowIndex: number,
    row: (Record<string, unknown> & { _id: string }) | undefined
  ) {
    if (menuItemId === 'edit') {
      setShowMaintenanceLogDialog(row?._id);
    }
  }

  return {
    tableData,
    tableDetails,
    handleMaintenanceLogActionClick,
    showMaintenanceLogDialog,
    setShowMaintenanceLogDialog,
    showCreateMaintenanceLogDialog,
    setShowCreateMaintenanceLogDialog,
  };
}
