import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteId } from '~/common/useRouteId';
import {
  useGetCreateLifecycleEventOptions,
  useGetCreateViperAssetOptions,
} from '~/queries/viper-asset';
import { formatPerson } from '~/utils/format-person';
import { formatDate } from '~/utils/date-utils';

import { GetViperAssetQuery, LifecycleEventType } from '~/__generated__/graphql';
import { useViperAssetEditSchema } from '~/pages/ViperAsset/Overview/schemas/useViperAssetEditSchema';
import { useValidateLifecycleEvent } from '../../useValidateLifecycleEvent';

const nextSteps = {
  [LifecycleEventType.Installation]: LifecycleEventType.Commissioning,
  [LifecycleEventType.Commissioning]: LifecycleEventType.Decommissioning,
  [LifecycleEventType.Decommissioning]: LifecycleEventType.Uninstallation,
  [LifecycleEventType.Uninstallation]: LifecycleEventType.Installation,
};

export function useOverviewTab(viperAsset: GetViperAssetQuery['viperAsset']) {
  const { t } = useTranslation('viper-asset');
  const id = useRouteId();

  const [creating, setCreating] = useState<LifecycleEventType | undefined>();
  const [editing, setEditing] = useState<string | undefined>();
  const [showLifecycleDialog, setShowLifecycleDialog] = useState(false);
  const [showCreateFirmwareLogDialog, setShowCreateFirmwareLogDialog] = useState(false);

  const { data: options, loading: optionsLoading } = useGetCreateViperAssetOptions(
    !(creating || editing)
  );
  const { data: lifecycleOptions, loading: lifecycleOptionsLoading } =
    useGetCreateLifecycleEventOptions(!(creating || editing));

  const resetAllForms = useCallback(() => {
    setEditing(undefined);
    setCreating(undefined);
  }, []);

  const validate = useValidateLifecycleEvent(
    creating,
    viperAsset,
    lifecycleOptions?.channels,
    editing
  );

  const handleCreateFirmwareRevisionClick = () => {
    setShowCreateFirmwareLogDialog(true);
  };

  const remoteAccessOptions = useMemo(
    () => [
      { id: true, name: t('remoteAccessOptions/yes') },
      { id: false, name: t('remoteAccessOptions/no') },
    ],
    [t]
  );

  const getNewLifecycleEvent = useCallback(() => {
    const nextStep =
      nextSteps[
        viperAsset?.latestLifecycleEvent?.eventType ?? LifecycleEventType.Uninstallation
      ];
    setCreating(nextStep);
  }, [viperAsset?.latestLifecycleEvent?.eventType]);

  const hasHistory = useMemo(
    () => !!viperAsset?.lifecycleEvents.length && viperAsset?.lifecycleEvents.length > 0,
    [viperAsset?.lifecycleEvents.length]
  );

  const lifecycleHistory = useMemo(
    () => viperAsset?.lifecycleEvents.slice(1),
    [viperAsset?.lifecycleEvents]
  );

  const lifeCycleHistory: Record<string, unknown>[] | undefined = useMemo(
    () =>
      lifecycleHistory?.map((lifecycleEvent) => ({
        _id: lifecycleEvent?.id,
        [t('viper-asset-forms::lifecycle/history/status')]: t(
          `viper-assets::installation-status/${lifecycleEvent?.eventType}`
        ),
        [t('viper-asset-forms::lifecycle/history/date')]: formatDate(
          lifecycleEvent?.dateOfLifecycleEvent
        ),
      })),
    [t, lifecycleHistory]
  );
  const lifeCycleHistoryDetails: Record<string, unknown>[] | undefined = useMemo(
    () =>
      lifecycleHistory?.map((lifecycleEvent) => {
        const details = {
          _id: lifecycleEvent?.id,
        };
        switch (lifecycleEvent.eventType) {
          case LifecycleEventType.Installation:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/installation/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/installation/type')]:
                lifecycleEvent?.installationType?.name,
              [t('viper-asset-forms::lifecycle/installation/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Commissioning:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/commissioning/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/commissioning/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Decommissioning:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/decommissioning/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/decommissioning/reason')]:
                lifecycleEvent?.decommissionReason?.name,
              [t('viper-asset-forms::lifecycle/decommissioning/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Uninstallation:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/uninstallation/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/uninstallation/status')]:
                lifecycleEvent?.uninstallStatus?.name,
              [t('viper-asset-forms::lifecycle/uninstallation/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          default:
            return details;
        }
      }),
    [t, lifecycleHistory]
  );

  const schema = useViperAssetEditSchema({
    parts: options?.parts,
    manufacturers: options?.manufacturers,
    commsTypes: options?.commsTypes,
    firmwareRevisions: options?.firmwareRevisions,
    ipAssignmentMethods: options?.ipAssignmentMethods,
    rootRevisionPasswords: options?.rootRevisionPasswords,
    customers: options?.customers,
    createLifecycleOptions: lifecycleOptions,
    remoteAccessOptions,
    viperAsset,
    editing,
    creating,
    lifeCycleHistory,
    lifeCycleHistoryDetails,
    getNewLifecycleEvent,
    handleCreateFirmwareRevisionClick,
  });

  return {
    schema,
    optionsLoading: optionsLoading || lifecycleOptionsLoading,
    creating,
    editing,
    setEditing,
    showLifecycleDialog,
    hasHistory,
    resetAllForms,
    setShowLifecycleDialog,
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    validate,
    id,
  };
}
