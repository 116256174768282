import React from 'react';
import { Table } from '~/components/Table/Table';
import { EnrichedCustomerAssetRow } from '~/pages/CustomerAssets/types';
import { useTranslation } from 'react-i18next';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';

import { formatDate } from '~/utils/date-utils';
import Box from '@mui/material/Box';
import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';

interface CustomerAssetsExpandableRowProps {
  row: EnrichedCustomerAssetRow;
}

export const CustomerAssetsExpandableRow = ({ row }: CustomerAssetsExpandableRowProps) => {
  const { t } = useTranslation('customer-assets-expandable-row');
  const { vLifeLicences } = row;

  if (!vLifeLicences.length) {
    return <></>;
  }

  const licenceTypeText = t('licence-type');
  const startDateText = t('start-date');
  const endDateText = t('end-date');

  const mapVLifeConfigToTableRow = (statusWindow: VLifeStatusWindow) => {
    const { startDate, endDate, status, config } = statusWindow;

    return {
      _id: config?.id ?? -1,
      [licenceTypeText]: {
        label: <StatusPill status={status} />,
        textValue: config?.vlifeLicence.vlifeLicenceType.name,
        value: config?.vlifeLicence.vlifeLicenceType.name,
      },
      [startDateText]: formatDate(startDate?.toISOString()),
      [endDateText]: formatDate(endDate?.toISOString()),
    };
  };

  const data = vLifeLicences.map(mapVLifeConfigToTableRow);

  return (
    <Box
      sx={{
        padding: 4,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Table
        muted
        embedded={false}
        data={data ?? []}
        omittedKeys={['_id']}
        itemsPerPage={100}
        omittedSortKeys={[licenceTypeText, startDateText, endDateText]}
      />
    </Box>
  );
};
