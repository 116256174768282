import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetChannelQuery } from '~/__generated__/graphql';

interface GetGeneralEditSchemaProps {
  t: TFunction;
  channel?: GetChannelQuery['channel'];
  editing?: boolean;
}

export function getGeneralEditSchema({ t, channel }: GetGeneralEditSchemaProps): Schema {
  return {
    fields: [
      {
        name: 'notes',
        label: t('customer-asset-forms::notes'),
        component: componentTypes.TEXTAREA,
        initialValue: channel?.notes,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
}
