import { Button } from '@mui/material';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventType, GetVLogFilesDocument } from '~/__generated-measurement__/graphql';
import {
  getVlogFileDownloadEndpoint,
  measurementApolloClient,
} from '~/common/graphql-measurement';
import { ACTION_EDIT } from '~/components/ActionMenu';
import { Actions } from '~/components/Actions/Actions';
import { RowType } from '~/components/DataList';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { Pill } from '~/components/Pill';
import { ComplexValueType, Table } from '~/components/Table/Table';
import { Toast } from '~/components/Toast';
import {
  useGetVLogFileDownloadToken,
  useGetVLogFiles,
} from '~/queries-measurement/measurement';
import { formatDateTime } from '~/utils/date-utils';
import { UploadDialog } from '~/components/upload/UploadDialog';
import { useUpload } from '~/components/upload/useUpload';
import { env } from '~/config/environmentVariables';

const colors: Record<
  string,
  | 'error'
  | 'success'
  | 'info'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'disabled'
  | 'action'
  | undefined
> = {
  [EventType.Pending]: 'secondary',
  [EventType.Processing]: 'warning',
  [EventType.Completed]: 'success',
  [EventType.Error]: 'error',
  [EventType.Uploading]: 'action',
};

export const IndicatorPill: React.FC<{ eventType: string | undefined }> = ({
  eventType: eventTypeId = EventType.Pending,
}) => {
  const { t } = useTranslation('v-log');

  // t('v-log::event-type/PENDING')
  // t('v-log::event-type/PROCESSING')
  // t('v-log::event-type/COMPLETED')
  // t('v-log::event-type/ERROR')
  // t('v-log::event-type/UPLOADING')
  return <Pill label={t(`event-type/${eventTypeId}`)} iconColor={colors[eventTypeId]} />;
};

export type VLogsRowData = {
  _id: string;
  name?: string | null | undefined;
  size?: ComplexValueType | null | undefined;
  person?: string | null | undefined;
  dateUploaded?: ComplexValueType | null | undefined;
  errorMessage?: string | null | undefined;
  [RowType.NODE]?: React.ReactNode;
};

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

// t('v-log::name')
// t('v-log::size')
// t('v-log::status')
// t('v-log::actions')
// t('v-log::dateUploaded')
// t('v-log::uploadPerson')
// t('v-log::errorMessage')

export const VLogs: React.FC = () => {
  const { t } = useTranslation('v-log');
  const {
    loading,
    error,
    data,
    closeError,
    setCloseError,
    handleActionClick,
    uploadFiles,
    nestedData,
  } = useVLogs();

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('upload-v-log'),
            onClick: () => {
              setUploadDialogOpen(true);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <UploadDialog
        open={uploadDialogOpen}
        onCancel={() => setUploadDialogOpen(false)}
        onUpload={(files) => {
          setUploadDialogOpen(false);
          uploadFiles(files);
        }}
      />

      <Table
        data={data}
        searchable
        omittedKeys={['_id']}
        translationKey='v-log'
        actionMenu={actionMenu}
        onActionClick={handleActionClick}
        nestedData={nestedData}
      />
    </>
  );
};

function refetchVLogFiles() {
  return measurementApolloClient.refetchQueries({ include: [GetVLogFilesDocument] });
}

function useVLogs() {
  const { t } = useTranslation('v-log');
  const { data: vlogs, loading, error } = useGetVLogFiles();
  const [getDownloadToken] = useGetVLogFileDownloadToken();
  const [closeError, setCloseError] = useState(false);
  const [editing, setEditing] = useState<string | undefined>();
  const { uploadFile } = useUpload();

  console.log({ editing }); // TODO: remove when editing is implemented

  const data: VLogsRowData[] = useMemo(() => {
    return (vlogs?.vlogFiles ?? []).map((vlog) => {
      const lastVLogEvent = vlog.events[vlog.events.length - 1];
      const dateUploaded = vlog.events.find(
        (event) => event.eventType === EventType.Pending
      )?.timestamp;

      return {
        _id: vlog.id,
        name: vlog.name,
        size: {
          label: formatFileSize(vlog.sizeBytes),
          value: vlog.sizeBytes,
        },
        dateUploaded: { label: formatDateTime(dateUploaded), value: dateUploaded },
        status: {
          label: <IndicatorPill eventType={lastVLogEvent?.eventType} />,
          textValue: t(`event-type/${lastVLogEvent?.eventType}`),
          value: lastVLogEvent?.eventType,
        },
      };
    });
  }, [t, vlogs?.vlogFiles]);

  const handleDownloadClick = useCallback(
    (id: string) => {
      return async (event: MouseEvent) => {
        event.preventDefault();
        try {
          const result = await getDownloadToken({ variables: { id } });
          location.href = getVlogFileDownloadEndpoint(
            id,
            result.data?.vlogFileDownloadToken || ''
          );
        } catch (error) {
          console.error(error);
        }
      };
    },
    [getDownloadToken]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (files) {
        await Promise.all(
          files.map((file) =>
            uploadFile(
              `${env.REACT_APP_MEASUREMENT_API_ENDPOINT}/vlogfile`,
              file,
              refetchVLogFiles,
              refetchVLogFiles
            )
          )
        );
      }
    },
    [uploadFile]
  );

  const nestedData = useMemo(
    () =>
      (vlogs?.vlogFiles ?? []).map((vlog) => {
        const errorEvent = vlog.events.find((event) => event.eventType === EventType.Error);
        const timestamps = vlog.events.reduce(
          (acc, event) => ({
            ...acc,
            [t(`event-type/${event.eventType}`)]: formatDateTime(event.timestamp),
          }),
          {}
        );

        let nested: VLogsRowData = {
          _id: vlog.id,
          [t('uploadPerson')]: undefined, // TODO: add person to vlog
          ...timestamps,
        };
        // In case of error, add error message
        if (errorEvent) {
          nested = {
            ...nested,
            [t('errorMessage')]: errorEvent.message,
          };
        }
        nested = {
          ...nested,
          [RowType.NODE]: (
            <Button
              variant='contained'
              color='neutral'
              onClick={handleDownloadClick(vlog.id)}
              size='small'
            >
              {t('download')}
            </Button>
          ),
        };

        return nested;
      }),
    [handleDownloadClick, t, vlogs?.vlogFiles]
  );

  const handleActionClick = (
    menuItemId: string,
    rowIndex: number,
    row: VLogsRowData | undefined
  ) => {
    if (menuItemId === 'edit') {
      setEditing(row?._id);
    }
  };

  return {
    loading,
    error,
    data,
    closeError,
    setCloseError,
    handleActionClick,
    nestedData,
    uploadFiles,
  };
}

export function formatFileSize(bytes: number) {
  switch (true) {
    case bytes < 1024:
      return bytes + ' bytes';
    case bytes < 1024 * 1024:
      return (bytes / 1024).toFixed(1) + ' KB';
    case bytes < 1024 * 1024 * 1024:
      return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
    default:
      return (bytes / (1024 * 1024 * 1024)).toFixed(1) + ' GB';
  }
}
