import { TFunction } from 'i18next';
import { GetCreateContactOptionsQuery } from '~/__generated__/graphql';
import { getContactEditSchema } from '../../edit/contact/contactEditSchema';

export const getContactCreateSchema = ({
  t,
  editing,
  createContactOptions,
}: {
  t: TFunction;
  editing?: string;
  createContactOptions?: GetCreateContactOptionsQuery | undefined;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-contact-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createContact',
            title: t('contacts::create-contact'),
            fields: getContactEditSchema({
              t,
              options: createContactOptions,
              editing,
            }).fields,
          },
        ],
      },
    ],
  };
};
