import { useGetInstalledViperAssetPeriodAndMaxIRCaps } from '~/queries/channel';
import { useGetStringReadingsForSerialNumbers } from '~/queries-measurement/measurement';
import { StringMeasurementType, StringReading } from '~/__generated-measurement__/graphql';
import { VLifeReportLogMessage } from '~/__generated__/graphql';

export interface UseGetVLifeEventsForChannelPeriodProps {
  channelId?: string;
  startDate?: string;
  endDate?: string;
}

export interface UseGetVLifeEventsForChannelPeriodResponse {
  loading: boolean;
  data: VLifeReportLogMessage[];
}

export const useGetVLifeEventsForChannelPeriod = ({
  channelId,
  startDate,
  endDate,
}: UseGetVLifeEventsForChannelPeriodProps): UseGetVLifeEventsForChannelPeriodResponse => {
  const { loading: loadingViperAssets, viperAssetPeriods } =
    useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate, endDate);
  const { data: stringReadings, loading: loadingStringReadings } =
    useGetStringReadingsForSerialNumbers(viperAssetPeriods, [
      StringMeasurementType.Message,
      StringMeasurementType.Softwareversion,
    ]);

  const mappedData = (stringReadings?.getStringReadingsForAssets ?? []).map(
    (stringReading: StringReading, index): VLifeReportLogMessage => ({
      id: `${index}`,
      message: stringReading.value ?? '',
      date: stringReading.time,
    })
  );

  const nonBlankData = mappedData.filter((event) => event.message.trim().length > 0);

  return {
    loading: loadingViperAssets || loadingStringReadings,
    data: nonBlankData,
  };
};
