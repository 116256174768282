import { useMemo } from 'react';
import { LicenceInsightsRow, ToolbarFilters } from '~/pages/LicenceInsights/types';
import { VLifeStatus } from '../CustomerAssets/types';
import { DateRange } from '~/common/types';
import {
  attritionFallsWithinEvaluationPeriod,
  flaggedForDecommissionFallsWithinEvaluationPeriod,
  gainFallsWithinEvaluationPeriod,
} from '~/pages/LicenceInsights/utils';

interface UseFilterRowsProps {
  rows: LicenceInsightsRow[];
  evaluationPeriod: DateRange;
  filters: ToolbarFilters;
}

export const useFilterRows = ({ rows, evaluationPeriod, filters }: UseFilterRowsProps) => {
  // Active statuses to include
  const activeStatuses = useMemo(
    () => [
      VLifeStatus.PAID,
      VLifeStatus.TRIAL,
      VLifeStatus.FREE_OF_CHARGE,
      VLifeStatus.NON_REFUNDABLE,
    ],
    []
  );

  // Filter out non-active rows
  const activeRows = useMemo(
    () => rows.filter((row) => activeStatuses.includes(row.status)),
    [activeStatuses, rows]
  );

  // Filter out rows that don't fall in date range
  const evaluationStartDate = new Date(evaluationPeriod.startDate);
  const evaluationEndDate = new Date(evaluationPeriod.endDate);

  const evaluationPeriodRows = activeRows.filter((row) => {
    return (
      row.startDate &&
      row.startDate.getTime() <= evaluationEndDate.getTime() &&
      row.endDate &&
      row.endDate.getTime() >= evaluationStartDate.getTime()
    );
  });

  // Filter out if gains / attrition / flagged for decommission filters are active
  const filteredRows = evaluationPeriodRows.filter((row) => {
    let leftIn = true;
    if (filters.gains && leftIn) {
      leftIn = gainFallsWithinEvaluationPeriod(row, evaluationPeriod);
    }
    if (filters.attrition && leftIn) {
      leftIn = attritionFallsWithinEvaluationPeriod(row, evaluationPeriod);
    }
    if (filters.flaggedForDecommission && leftIn) {
      leftIn = flaggedForDecommissionFallsWithinEvaluationPeriod(row);
    }
    return leftIn;
  });

  return {
    filteredRows,
  };
};
