import {
  Schema,
  componentTypes,
  validatorTypes,
  FieldApi,
  AnyObject,
  FormOptions,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetFieldQuery, GetCreateFieldOptionsQuery } from '~/__generated__/graphql';

interface GetCompanyEditSchemaProps {
  t: TFunction;
  field?: GetFieldQuery['field'] | null;
  countries?: GetCreateFieldOptionsQuery['countries'] | null;
  customers?: GetCreateFieldOptionsQuery['customers'] | null;
}

/**
 * This schema also serves as the create schema returned by getFieldCreateSchema
 */
export function getFieldEditSchema({
  t,
  countries,
  customers,
  field,
}: GetCompanyEditSchemaProps): Schema {
  const editSchema = {
    fields: [
      {
        name: 'name',
        label: t('field-forms::company-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: field?.name,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        sx: { gridColumnEnd: 'span 2', width: 550 },
      },
      {
        name: 'countryId',
        label: t('field-forms::country'),
        component: componentTypes.SELECT,
        sortAlphabetical: true,
        isSearchable: true,
        isRequired: true,
        initialValue: field?.country,
        options: countries,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'continentId',
        label: t('field-forms::continent'),
        component: componentTypes.PLAIN_TEXT,
        isSearchable: true,
        isRequired: true,
        initialValue: field?.country?.continent?.name,
        resolveProps: (
          _props: AnyObject,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _field: FieldApi<any, HTMLElement>,
          formOptions: FormOptions
        ) => {
          const selectedCountryId = formOptions.getState().values.countryId;
          const selectedCountry = countries?.find(
            (country) => country.id === selectedCountryId
          );
          return {
            initialValue: selectedCountry?.continent?.name,
          };
        },
      },
      {
        name: 'defaultCustomerId',
        label: t('field-forms::default-customer'),
        component: componentTypes.SELECT,
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: field?.defaultCustomer,
        options: customers,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'defaultOperatorId',
        label: t('field-forms::default-operator'),
        component: componentTypes.SELECT,
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: field?.defaultOperator,
        options: customers,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
    ],
  };
  return editSchema;
}
