import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';
import {
  NegatableDateRangeFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { EnrichedCustomerAssetRow, VLifeStatus } from '~/pages/CustomerAssets/types';
import {
  nonPaidVLifeStatuses,
  VLifeStatusWindow,
} from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { sortByStartDate } from '~/pages/CustomerAssets/utils';
import { filterStatusWindowsOutsideFilterDateRange } from '~/components/DataGrid/filters/custom/v-life-licences/filter-functions/utils';

export const getPaidToUnpaidStatusChangeFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableDateRangeFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { startDate, endDate, isNegated } = filterValues;

  if (!startDate || !endDate) {
    // No lowerbound or upperbound set - don't filter out anything
    return null;
  }

  return ({
    value: vLifeStatusWindows,
  }: GridCellParams<EnrichedCustomerAssetRow, VLifeStatusWindow[]>) => {
    // Immediately filter out rows with no configs
    if (!vLifeStatusWindows) {
      return isNegated;
    }

    // Filter out licences that don't overlap with filter date window
    const filteredStatusWindows = filterStatusWindowsOutsideFilterDateRange(
      vLifeStatusWindows,
      startDate,
      endDate
    );

    // Sort by date ascending
    filteredStatusWindows.sort(sortByStartDate);

    // Find transition from paid to unpaid
    const transitionFound = filteredStatusWindows.find(
      (currentStatusWindow, currentIndex, statusWindows) => {
        const nextStatusWindow = statusWindows[currentIndex + 1];
        return (
          nextStatusWindow &&
          currentStatusWindow.status === VLifeStatus.PAID &&
          nonPaidVLifeStatuses.includes(nextStatusWindow.status)
        );
      }
    );

    const keepItemIn = !!transitionFound;

    // Apply negation if present
    return isNegated ? !keepItemIn : keepItemIn;
  };
};
