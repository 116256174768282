import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getPlatformEditSchema } from '~/pages/schemas/edit/platform/platformEditSchema';
import { useGetPlatform, useUpdatePlatform } from '~/queries/channel';
import { mapValuesToVariables } from '~/components/Form/map-variables';

export const UpdatePlatformDialog: React.FC<{
  editId: string | undefined;
  onClose: () => void;
}> = ({ editId, onClose }) => {
  const { t } = useTranslation('platforms');

  return (
    <CreateEditEntityDialog
      open={!!editId}
      onClose={onClose}
      title={t('edit-platform')}
      errorMessage={t('error-creating')}
      successMessage={(platform) => t('updated-successfully', { platform })}
      editWarning={t('customer-assets::affect-multiple-channels')}
      useGetEntity={useGetPlatform}
      useMutate={useUpdatePlatform}
      entityId={editId}
      getSchema={({ t, entity }) =>
        getPlatformEditSchema({ t, platform: entity?.platform }).fields
      }
      handleMutate={async (update, values, fields) => {
        await update({
          variables: {
            id: editId as string,
            platformInput: mapValuesToVariables(fields, values),
          },
        });
        return values.name as string;
      }}
    />
  );
};
