import { Schema } from '@data-driven-forms/react-form-renderer';
import { NoLifecyclePane } from '~/pages/NoLifecyclePane';
import { Card } from '~/components/Card';
import { Highlights } from '~/pages/Highlights';
import { useGetHighlights } from '~/pages/ViperAsset/Overview/schemas/edit/useGetHighlights';
import {
  GetCreateLifecycleEventOptionsQuery,
  GetCreateViperAssetOptionsQuery,
  GetViperAssetQuery,
  LifecycleEventType,
} from '~/__generated__/graphql';
import { useCreateLifecycleSchema } from '~/pages/ViperAsset/Overview/schemas/edit/useCreateLifecycleSchema';
import { useLifecycleInstallationEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleInstallationEditSchema';
import { useLifecycleCommissioningEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleCommissioningEditSchema';
import { useLifecycleDecommissioningEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleDecommissioningEditSchema';
import { useLifecycleUninstallationEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleUninstallationEditSchema';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UseLifecycleEditSchemaProps {
  createLifecycleOptions?: GetCreateLifecycleEventOptionsQuery;
  customers?: GetCreateViperAssetOptionsQuery['customers'];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  lifeCycleHistory?: Record<string, unknown>[];
  lifeCycleHistoryDetails?: Record<string, unknown>[];
  editing?: string;
  creating?: string;
  getNewLifecycleEvent: (creating?: string) => void;
}

export const useLifecycleEditSchema = ({
  createLifecycleOptions,
  customers,
  viperAsset,
  lifeCycleHistory,
  lifeCycleHistoryDetails,
  editing,
  creating,
  getNewLifecycleEvent,
}: UseLifecycleEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset');

  return {
    fields: [
      {
        name: 'noLifecyclePanel',
        component: 'custom',
        customComponent: (
          <NoLifecyclePane
            onClick={() => {
              getNewLifecycleEvent(creating);
            }}
            buttonDisabled={!!(creating || editing)}
          />
        ),
        when: () =>
          !viperAsset?.latestLifecycleEvent &&
          (viperAsset?.lifecycleEvents as Record<string, unknown>[]).length === 0,
      },
      {
        name: 'highlights',
        component: 'custom',
        customComponent: (
          <Card sx={{ py: 3 }}>
            <Highlights
              highlights={
                useGetHighlights({
                  channel: viperAsset?.latestLifecycleEvent?.channel,
                })[
                  viperAsset?.latestLifecycleEvent?.eventType ||
                    LifecycleEventType.Installation
                ]
              }
              ctaCopy={t(
                `lifecycle-cta/${
                  viperAsset?.latestLifecycleEvent?.eventType ||
                  LifecycleEventType.Installation
                }`
              )}
              onClickCta={() => {
                getNewLifecycleEvent(creating);
              }}
              buttonDisabled={!!(creating || editing)}
            />
          </Card>
        ),
        when: () => viperAsset?.latestLifecycleEvent,
      },
      {
        name: creating ?? 'createPane',
        title: t(`create-lifecycle-event/${creating}`),
        component: 'panel',
        when: () => !!creating,
        fields: useCreateLifecycleSchema({
          creating,
          customers,
          ...createLifecycleOptions,
        }),
      },
      {
        name: LifecycleEventType.Installation,
        title: t('installation'),
        component: 'panel',
        nextStep: LifecycleEventType.Commissioning,
        when: () =>
          viperAsset?.latestLifecycleEvent?.eventType === LifecycleEventType.Installation,
        fields: useLifecycleInstallationEditSchema({
          lifecycleEvent: viperAsset?.latestLifecycleEvent,
          editing: editing === LifecycleEventType.Installation,
          customers,
          ...createLifecycleOptions,
        }).fields,
      },
      {
        name: LifecycleEventType.Commissioning,
        title: t('commissioning'),
        component: 'panel',
        nextStep: LifecycleEventType.Decommissioning,
        when: () =>
          viperAsset?.latestLifecycleEvent?.eventType === LifecycleEventType.Commissioning,
        fields: useLifecycleCommissioningEditSchema({
          lifecycleEvent: viperAsset?.latestLifecycleEvent,
          editing: editing === LifecycleEventType.Decommissioning,
          customers,
          ...createLifecycleOptions,
        }).fields,
      },
      {
        name: LifecycleEventType.Decommissioning,
        title: t('decommissioning'),
        component: 'panel',
        nextStep: LifecycleEventType.Uninstallation,
        when: () =>
          viperAsset?.latestLifecycleEvent?.eventType === LifecycleEventType.Decommissioning,
        fields: useLifecycleDecommissioningEditSchema({
          lifecycleEvent: viperAsset?.latestLifecycleEvent,
          editing: editing === LifecycleEventType.Decommissioning,
          customers,
          ...createLifecycleOptions,
        }).fields,
      },
      {
        name: LifecycleEventType.Uninstallation,
        title: t('uninstallation'),
        component: 'panel',
        nextStep: 'manufacturing',
        when: () =>
          viperAsset?.latestLifecycleEvent?.eventType === LifecycleEventType.Uninstallation,
        fields: useLifecycleUninstallationEditSchema({
          lifecycleEvent: viperAsset?.latestLifecycleEvent,
          editing: editing === LifecycleEventType.Uninstallation,
          customers,
          ...createLifecycleOptions,
        }).fields,
      },
      {
        name: 'lifeCycleHistory',
        title: t('lifecycle-history'),
        component: 'list',
        data: lifeCycleHistory,
        nestedData: lifeCycleHistoryDetails,
        omittedKeys: ['_id'],
      },
    ],
  };
};
