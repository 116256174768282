import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { useTranslation } from 'react-i18next';

interface GetGeneralEditSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useGeneralEditSchema = ({ viperAsset }: GetGeneralEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'generalNotes',
        label: t('generalNotes'),
        component: componentTypes.TEXTAREA,
        initialValue: viperAsset?.generalNotes,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
};
