import { TFunction } from 'i18next';
import {
  GetChannelQuery,
  GetCustomerAssetMaintenanceLogOptionsQuery,
} from '~/__generated__/graphql';
import { getCustomerAssetMaintenanceLogEditSchema } from '../../edit/customerAssetMaintenanceLog/customerAssetMaintenanceLogEditSchema';

export const getCustomerAssetMaintenanceLogCreateSchema = ({
  t,
  options,
  maintenanceLog,
}: {
  t: TFunction;
  options: GetCustomerAssetMaintenanceLogOptionsQuery | undefined;
  maintenanceLog?: NonNullable<GetChannelQuery['channel']>['maintenanceLogs'][0];
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-maintenance-log-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'maintenanceLog',
            title: t('customer-asset::maintenance-log'),
            fields: getCustomerAssetMaintenanceLogEditSchema({
              t,
              options,
              maintenanceLog,
            }).fields,
          },
        ],
      },
    ],
  };
};
