import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateUmbilical, useGetCreateUmbilicalOptions } from '~/queries/channel';
import { Platform } from '~/__generated__/graphql';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getUmbilicalCreateSchema } from '../../schemas/create/umbilical/umbilicalCreateSchema';

export const CreateUmbilicalDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  platform?: Platform;
}> = ({ open, onClose, platform }) => {
  const { t } = useTranslation('umbilicals');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-umbilical')}
      errorMessage={t('error-creating')}
      successMessage={() => t('created-successfully')}
      useGetOptions={useGetCreateUmbilicalOptions}
      useMutate={useCreateUmbilical}
      getSchema={({ t, options }) =>
        getUmbilicalCreateSchema({
          t,
          manufacturers: options?.manufacturers,
          materialTypes: options?.materialTypes,
          platformName: platform?.name,
        }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            umbilicalInput: {
              length: values.length as number,
              manufacturerId: values.manufacturerId as string,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              platformId: platform!.id,
              approxInstallationDate: values.approxInstallationDate as number,
              materialTypeId: values.materialTypeId as string,
            },
          },
        });
        return '-';
      }}
    />
  );
};
