import React from 'react';
import { DefaultRawDatum, PieTooltipProps } from '@nivo/pie';
import { Paper, Grow, Typography, styled } from '@mui/material';
import { ComputedBarDatum, BarCustomLayerProps, BarDatum, BarTooltipProps } from '@nivo/bar';
import { ComputedBoxPlotSummary, BoxPlotCustomLayerProps, BoxPlotDatum } from '@nivo/boxplot';
import { VLifeStatus } from '~/__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { theme } from '~/themes/mui';
import { getCustomLabel } from './GainsAttritionUtils';
import { attritionPadding } from './defaults';
import { getMonthYearName } from '~/pages/Dashboard/useGainAttritionGraph';

const Tooltip = styled(Paper)`
  padding: 0.25rem 0.5rem;
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    top: 95%; /* removes gap when animating tooltip */
    left: 50%;
    margin-left: -9px;
    border-width: 9px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

Tooltip.defaultProps = { role: 'tooltip' };

export const PieTooltip: React.FC<
  PieTooltipProps<DefaultRawDatum> & { allData: DefaultRawDatum[] | undefined }
> = ({ datum, allData }) => {
  const sum = allData?.reduce((acc, curr) => acc + curr.value, 0);

  const percentage = sum ? `${((datum?.data.value / sum) * 100).toFixed(2)}%` : '0%';
  return (
    <Grow in={!!datum?.data}>
      <Tooltip>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }} align='center'>
          {datum?.data?.id}
        </Typography>
        <Typography sx={{ fontSize: 14 }} align='center'>
          {datum?.data?.value} ({percentage})
        </Typography>
      </Tooltip>
    </Grow>
  );
};

export const BarTooltip: React.FC<
  BarTooltipProps<BarDatum> & { allData: BarDatum[] | undefined }
> = (props) => {
  const sum = props.allData?.reduce((acc, curr) => acc + Number(curr[props.id]), 0);

  const percentage = sum
    ? `${((Number(props.data[props.id]) / sum) * 100).toFixed(2)}%`
    : '0%';

  return (
    <Grow in={!!props.label}>
      <Tooltip>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }} align='center'>
          {`${props.indexValue}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} align='center'>
          {props.value} ({percentage})
        </Typography>
      </Tooltip>
    </Grow>
  );
};

export const BoxPlotTooltip: React.FC<ComputedBoxPlotSummary> = (tooltip) => {
  const { t } = useTranslation('dashboard');
  return (
    <Grow in={!!tooltip.label}>
      <Tooltip>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }} align='center'>
          {tooltip.label}
        </Typography>
        <Typography sx={{ fontSize: 14 }} align='center'>
          {`${t('total-v-life-licences')}: ${getCustomLabel(tooltip, 0) ?? 'N/A'}`}
        </Typography>
      </Tooltip>
    </Grow>
  );
};

export const CustomAttritionLabels: React.FC<
  Pick<BoxPlotCustomLayerProps<BoxPlotDatum>, 'boxPlots'>
> = ({ boxPlots }) => {
  return (
    <g>
      {boxPlots.map((boxPlot, index) => {
        const ariaLabel = `${boxPlot.group} ${boxPlot.subGroup} ${boxPlot.data.extrema[0]} - ${boxPlot.data.extrema[1]}`;
        return (
          <g transform={`translate(0, ${boxPlot.y - attritionPadding / 2})`} key={boxPlot.key}>
            <text
              role='graphics-note'
              aria-label={ariaLabel}
              textAnchor='middle'
              style={{ fontSize: 14 }}
              transform={`translate(${boxPlot.x + boxPlot.width / 2}, 0)`}
            >
              {getCustomLabel(boxPlot, index) ?? 'N/A'}
            </text>
          </g>
        );
      })}
    </g>
  );
};

export type CustomerVerticalGridProps = Pick<
  BoxPlotCustomLayerProps<BoxPlotDatum>,
  'boxPlots' | 'innerHeight' | 'innerWidth'
>;

export const CustomVerticalGrid = ({
  boxPlots,
  innerHeight,
  innerWidth,
}: CustomerVerticalGridProps) => {
  const currentMonth = getMonthYearName(new Date());

  return (
    <>
      {boxPlots.map((boxPlot, index, array) => {
        return (index + 1) % 2 ? null : (
          <>
            <g key={boxPlot.key}>
              <line
                data-testid='vertical-grid-line'
                transform={`translate(-${boxPlot.width / 2},0)`}
                opacity='1'
                x1={boxPlot.x}
                x2={boxPlot.x}
                y1={0}
                y2={innerHeight}
                stroke='#dddddd'
                strokeWidth='1'
              ></line>
            </g>
            {/* render grey rectangle behind current month */}
            {boxPlot.group === currentMonth && (
              <g key={`${boxPlot.key}-current`}>
                <rect
                  data-testid={`highlight-rect-month`}
                  x={boxPlot.x - boxPlot.width / 2}
                  y={0}
                  width={(array[index + 2]?.x ?? innerWidth) - boxPlot.x}
                  height={innerHeight}
                  fill='#dddddd44'
                />
              </g>
            )}
          </>
        );
      })}
    </>
  );
};

export const CustomBarTooltip: React.FC<BarTooltipProps<BarDatum>> = (props) => {
  const allValues = { ...props.data };
  delete allValues['indexBy'];
  delete allValues['month'];
  delete allValues['activeCount'];

  const { t } = useTranslation('dashboard');

  let sum = 0;
  for (const key in allValues) {
    sum += Number(allValues[key]);
  }
  const percentage = `${((Number(props.data[props.id]) / sum) * 100).toFixed(2)}%`;
  return (
    <Grow in={!!props.label}>
      <Tooltip>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }} align='center'>
          {t(`${props.id}`) + ` – ${props.indexValue}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} align='center'>
          {props.value} ({percentage})
        </Typography>
      </Tooltip>
    </Grow>
  );
};

export const TopLabels = ({ bars }: BarCustomLayerProps<BarDatum>) => {
  const padding = 8;
  const barsList: Partial<ComputedBarDatum<BarDatum> & { bars: string[] }>[] = [];
  bars.forEach((currBar) => {
    const key = currBar.key.slice(currBar.key.indexOf('.') + 1);
    const keyCheck = barsList.find((element) => element.key === key);
    const keyCheckIndex = barsList.findIndex((element) => element.key === key);
    if (keyCheck && currBar.data.value !== 0) {
      barsList[keyCheckIndex]['bars']?.push(currBar.data.id as string);
    } else {
      barsList.push({
        key: key,
        bars: [currBar.data.id as string],
      });
    }
  });
  return (
    <>
      {bars.map((bar, index, array) => {
        const barListKey = barsList.findIndex(
          (element) => element.key === bar.key.slice(bar.key.indexOf('.') + 1)
        );
        const lastBarInGroup =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          barsList[barListKey].bars?.[barsList[barListKey].bars!.length - 1];
        if (bar.data.id === lastBarInGroup) {
          return (
            <svg
              key={bar.key}
              style={{
                overflow: 'visible',
              }}
              role='graphics-note'
            >
              {index === array.length - 1 && (
                <g>
                  <rect
                    data-testid={`highlight-rect-month`}
                    x={bar.x - padding}
                    y={bar.y - padding - 50}
                    width={bar.width + padding * 2}
                    height={310 - bar.y + padding * 2}
                    fill='#eee'
                    stroke='#ccc'
                    rx={4}
                  />
                </g>
              )}
              <g>
                <text
                  x={bar.x + bar.width / 2}
                  y={bar.y + 25 / 2 - 56}
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                  dominantBaseline='middle'
                  textAnchor='middle'
                >
                  {((bar.data.data[VLifeStatus.ActiveTrial] as number) ?? 0) +
                    ((bar.data.data[VLifeStatus.ActiveFoc] as number) ?? 0) +
                    ((bar.data.data[VLifeStatus.ActiveNonRefundable] as number) ?? 0) +
                    ((bar.data.data[VLifeStatus.ActivePaid] as number) ?? 0)}{' '}
                </text>
                <text
                  x={bar.x + bar.width / 2}
                  y={bar.y + 25 / 2 - 40}
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                  dominantBaseline='middle'
                  textAnchor='middle'
                >
                  {(bar.data.data[VLifeStatus.ActivePaid] as number) ?? 0}{' '}
                </text>
                {bar.data.data.activeCount && (
                  <text
                    x={bar.x + bar.width / 2}
                    y={bar.y + 25 / 2 - 25}
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                    }}
                    dominantBaseline='middle'
                    textAnchor='middle'
                    fill={theme.palette.text.secondary}
                  >
                    {`(CU: ${bar.data.data.activeCount})`}
                  </text>
                )}
              </g>
            </svg>
          );
        }
      })}
    </>
  );
};
