import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreatePlatform } from '~/queries/channel';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getPlatformCreateSchema } from '../../schemas/create/platform/platformCreateSchema';

export const CreatePlatformDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation('platforms');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-platform')}
      errorMessage={t('error-creating')}
      successMessage={(platform) => t('created-successfully', { platform })}
      useMutate={useCreatePlatform}
      getSchema={({ t }) => getPlatformCreateSchema({ t }).fields[0].fields[0].fields}
      handleMutate={async (create, values) => {
        await create({
          variables: {
            platformInput: {
              name: values.name as string,
              waterDepth: values.waterDepth as number,
            },
          },
        });
        return values.name as string;
      }}
    />
  );
};
