import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getFirmwareLogCreateSchema } from '~/pages/schemas/create/firmwareLog/firmwareLogCreateSchema';
import { useEditFirmwareLog, useGetFirmwareLogOptions } from '~/queries/viper-asset';
import { formatDate } from '~/utils/date-utils';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { mapValuesToVariables } from '~/components/Form/map-variables';

export const UpdateFirmwareLogDialog: React.FC<{
  id: string;
  open: boolean;
  // @ts-expect-error type too deep for TS
  firmwareLogs?: GetViperAssetQuery['viperAsset']['firmwareLogs'];
  onClose: () => void;
  editId?: string;
}> = ({ open, onClose, id, firmwareLogs, editId }) => {
  const { t } = useTranslation('viper-asset');

  const firmwareRevisionIndex = firmwareLogs?.findIndex(
    // @ts-expect-error type too deep for TS
    (log: GetViperAssetQuery['viperAsset']['firmwareLogs']) => log.id === editId
  );
  const firmwareRevision = firmwareLogs[firmwareRevisionIndex];
  const previousFirmwareRevision = firmwareLogs[firmwareRevisionIndex + 1];

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('edit-firmware-log')}
      errorMessage={t(`error-editing-firmware-log`)}
      successMessage={() => t('firmware-log-edited-successfully')}
      useGetOptions={useGetFirmwareLogOptions}
      useMutate={useEditFirmwareLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getFirmwareLogCreateSchema({ t, options, firmwareRevision, previousFirmwareRevision })
          .fields[0].fields[0].fields
      }
      handleMutate={async (update, values, fields) => {
        await update({
          variables: {
            id: editId as string,
            firmwareUpdateInput: mapValuesToVariables(fields, values),
          },
        });
        return formatDate(values.firmwareEndDate as string) as string;
      }}
    />
  );
};
