import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { DateRange } from '~/common/types';

export const gainFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow,
  evaluationPeriod: DateRange
): boolean => {
  return !!(
    row.gain &&
    row.startDate &&
    row.startDate >= evaluationPeriod.startDate &&
    row.startDate <= evaluationPeriod.endDate
  );
};

export const attritionFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow,
  evaluationPeriod: DateRange
): boolean => {
  return !!(
    row.attrition &&
    row.endDate &&
    row.endDate >= evaluationPeriod.startDate &&
    row.endDate <= evaluationPeriod.endDate
  );
};

export const flaggedForDecommissionFallsWithinEvaluationPeriod = (
  row: LicenceInsightsRow
): boolean => {
  return !!row.config?.flaggedForDecommission;
};
