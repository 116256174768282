import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import {
  useGetCreatePcsOptions,
  useGetProductionControlSystem,
  useUpdateProductionControlSystem,
} from '~/queries/channel';
import { getPcsEditSchema } from '~/pages/schemas/edit/pcs/pcsEditSchema';
import { mapValuesToVariables } from '~/components/Form/map-variables';

export const UpdatePcsDialog: React.FC<{
  editId: string | undefined;
  onClose: () => void;
}> = ({ editId, onClose }) => {
  const { t } = useTranslation('production-control-systems');

  return (
    <CreateEditEntityDialog
      open={!!editId}
      onClose={onClose}
      title={t('edit-pcs')}
      errorMessage={t('error-creating')}
      successMessage={(platform) => t('updated-successfully', { platform })}
      editWarning={t('customer-assets::affect-multiple-channels')}
      useGetEntity={useGetProductionControlSystem}
      useMutate={useUpdateProductionControlSystem}
      useGetOptions={useGetCreatePcsOptions}
      entityId={editId}
      getSchema={({ t, options, entity }) =>
        getPcsEditSchema({
          t,
          productionControlSystem: entity?.productionControlSystem,
          platformName: entity?.productionControlSystem?.platform.name,
          manufacturers: options?.manufacturers,
          electricalDistributionTypes: options?.electricalDistributionTypes,
          electricalTopologies: options?.electricalTopologies,
        }).fields
      }
      handleMutate={async (update, values, fields) => {
        const variables = mapValuesToVariables(fields, values);
        const productionControlSystemInput: Record<string, unknown> = {
          ...variables,
          pcsApproxInstallationDate: variables.pcsApproxInstallationDateId as number,
          epuApproxInstallationDate: variables.epuApproxInstallationDateId as number,
        };
        delete productionControlSystemInput.epuApproxInstallationDateId;
        delete productionControlSystemInput.pcsApproxInstallationDateId;
        await update({
          variables: {
            id: editId as string,
            productionControlSystemInput,
          },
        });
        return '-';
      }}
    />
  );
};
