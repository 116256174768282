import { useMutation, useQuery } from '@apollo/client';
import {
  CreateContactDocument,
  GetContactDocument,
  GetCreateContactOptionsDocument,
  GetPeopleDocument,
  UpdateContactDocument,
} from '~/__generated__/graphql';

export function useGetContacts() {
  return useQuery(GetPeopleDocument);
}

export function useGetCreateContactOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateContactOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useCreateContact() {
  return useMutation(CreateContactDocument);
}

export function useGetContact(id: string, options?: { skip?: boolean }) {
  return useQuery(GetContactDocument, { variables: { id }, skip: options?.skip });
}

export function useUpdateContact() {
  return useMutation(UpdateContactDocument);
}
