import { VLifeConfigWithStatus, VLifeStatus } from '~/pages/CustomerAssets/types';

export interface NegatableVLifeLicenceChangeRangeFilters {
  isNegated: boolean;
  fromStatuses: number[];
  toStatuses: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface VLifeStatusWindow {
  startDate?: Date;
  endDate?: Date;
  status: number;
  config?: VLifeConfigWithStatus;
  gain?: boolean;
  attrition?: boolean;
}

export const nonPaidVLifeStatuses = [
  VLifeStatus.FREE_OF_CHARGE,
  VLifeStatus.TRIAL,
  VLifeStatus.INACTIVE,
  VLifeStatus.DEACTIVATED,
  VLifeStatus.NEVER_SET_UP,
  VLifeStatus.SUSPENDED,
];
