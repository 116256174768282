import React from 'react';
import { FormGroup } from '~/components/Form/FormGroup';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { SaveError } from '~/components/Form/SaveError';
import { Dialog } from '~/components/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useReviewVLifeReportFormHook } from './useReviewVLifeReportForm';
import { VLifeReportFormState } from '~/__generated__/graphql';
import { ReportFormGraphs } from './Graphs/ReportFormGraphs';
import { useVLifeReportsHistory } from './useVLifeReportsHistory';

export const ReviewVLifeReport: React.FC = () => {
  const {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onSendForApproval,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmReview,
    showConfirmReview,
    handleConfirmReview,
    reviewReportFormError,
    channelId,
    startDate,
    endDate,
  } = useReviewVLifeReportFormHook();
  const { t } = useTranslation('review-v-life-report-form');
  const { previousStartDate, previousEndDate } = useVLifeReportsHistory(channelId);

  if (loading) {
    return <LoadingSkeleton />;
  }
  if (reportForm?.vlifeReportForm?.state !== VLifeReportFormState.Created) {
    return <strong>{t('report-not-found')}</strong>;
  }
  return (
    <>
      <ReportFormGraphs
        channelId={channelId}
        currentDates={{ startDate, endDate }}
        previousDates={{ startDate: previousStartDate, endDate: previousEndDate }}
      />
      <FormGroup
        data={schema}
        loading={false}
        // validator={validator} // add validator here if needed
        onCancel={onCancel}
        onSubmit={onSendForApproval}
        onAdditionalSubmit={onSendForReview}
        setValues={(values) => setValues(values)}
        saving={saving}
        extraFooterContent={
          <>
            <Button color='error' variant='contained' onClick={deleteForm}>
              {t('viper::delete')}
            </Button>
            <div
              style={{
                flexGrow: 1,
              }}
            />
          </>
        }
        submitButtonText={t('send-for-approval')}
        additionalSubmitButtonText={t('send-for-review')}
      />
      <Dialog
        open={showConfirmReview !== undefined}
        onClose={() => setShowConfirmReview(undefined)}
        title={showConfirmReview === 'approve' ? t('confirm-approve') : t('confirm-review')}
        content={showConfirmReview === 'approve' ? t('approve-content') : t('review-content')}
        confirmText={t('viper::sign')}
        cancelText={t('viper::cancel')}
        onConfirm={handleConfirmReview}
        loading={saving}
      />
      <SaveError errorState={reviewReportFormError}>
        {t('error-saving-v-life-report-review')}
      </SaveError>
      <Dialog
        open={showDelete}
        title={t('delete-report/title')}
        content={t('delete-report/content')}
        onClose={() => {
          setShowDelete(false);
        }}
        confirmText={t('viper::delete')}
        cancelText={t('viper::cancel')}
        confirmColor='error'
        onConfirm={handleConfirmDelete}
        loading={loading}
      />
      <SaveError errorState={deleteError}>{t('delete-report/error')}</SaveError>
      {/* TODO can we display a toast on another page? */}
    </>
  );
};
