import {
  Schema,
  componentTypes,
  validatorTypes,
  FieldApi,
  AnyObject,
  FormOptions,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetCustomerQuery, GetCreateCompanyOptionsQuery } from '~/__generated__/graphql';

interface GetCompanyEditSchemaProps {
  t: TFunction;
  company?: GetCustomerQuery['customer'] | null;
  countries?: GetCreateCompanyOptionsQuery['countries'] | null;
  editing?: string;
}

/**
 * This schema also serves as the create schema returned by getCompanyCreateSchema
 */
export function getCompanyEditSchema({
  t,
  countries,
  company,
}: GetCompanyEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'name',
        label: t('company-forms::company-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: company?.name,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'headquarters',
        label: t('company-forms::headquarters'),
        component: componentTypes.TEXT_FIELD,
        initialValue: company?.headquarters,
      },
      {
        name: 'address',
        label: t('company-forms::address'),
        component: componentTypes.TEXT_FIELD,
        initialValue: company?.address,
      },
      {
        name: 'countryId',
        label: t('company-forms::country'),
        component: componentTypes.SELECT,
        isRequired: true,
        isSearchable: true,
        sortAlphabetical: true,
        initialValue: company?.country,
        options: countries,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'continent',
        label: t('company-forms::continent'),
        component: componentTypes.PLAIN_TEXT,
        isRequired: true,
        initialValue: company?.country?.continent?.name,
        condition: { when: 'countryId', isNotEmpty: true },
        resolveProps: (
          _props: AnyObject,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _field: FieldApi<any, HTMLElement>,
          formOptions: FormOptions
        ) => {
          const selectedCountryId = formOptions.getState().values.countryId;
          const selectedCountry = countries?.find(
            (country) => country.id === selectedCountryId
          );
          return {
            initialValue: selectedCountry?.continent?.name,
          };
        },
      },
    ],
  };
  return createSchema;
}
