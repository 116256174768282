import {
  GetCreateLifecycleEventOptionsQuery,
  GetCreateViperAssetOptionsQuery,
  GetViperAssetQuery,
  LifecycleEventType,
} from '~/__generated__/graphql';
import { useCommercialEditSchema } from './edit/useCommercialEditSchema';
import { useConfigurationEditSchema } from './edit/useConfigurationEditSchema';
import { useIdentificationEditSchema } from './edit/useIdentificationEditSchema';
import { useManufacturingEditSchema } from './edit/useManufacturingEditSchema';
import { useGeneralEditSchema } from './edit/useGeneralEditSchema';
import { componentTypes } from '@data-driven-forms/react-form-renderer';
import colors from '~/themes/colors';
import { useTranslation } from 'react-i18next';
import { useLifecycleEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/useLifecycleEditSchema';

// t('viper-asset::create-lifecycle-event/INSTALLATION')
// t('viper-asset::create-lifecycle-event/COMMISSIONING')
// t('viper-asset::create-lifecycle-event/DECOMMISSIONING')
// t('viper-asset::create-lifecycle-event/UNINSTALLATION')

interface GetViperAssetEditSchemaProps {
  parts?: GetCreateViperAssetOptionsQuery['parts'];
  manufacturers?: GetCreateViperAssetOptionsQuery['manufacturers'];
  commsTypes?: GetCreateViperAssetOptionsQuery['commsTypes'];
  firmwareRevisions?: GetCreateViperAssetOptionsQuery['firmwareRevisions'];
  ipAssignmentMethods?: GetCreateViperAssetOptionsQuery['ipAssignmentMethods'];
  rootRevisionPasswords?: GetCreateViperAssetOptionsQuery['rootRevisionPasswords'];
  createLifecycleOptions?: GetCreateLifecycleEventOptionsQuery;
  customers?: GetCreateViperAssetOptionsQuery['customers'];
  remoteAccessOptions?: { id: boolean; name: string }[];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  lifeCycleHistory?: Record<string, unknown>[];
  lifeCycleHistoryDetails?: Record<string, unknown>[];
  editing?: string;
  creating?: string;
  getNewLifecycleEvent: (creating?: string) => void;
  handleCreateFirmwareRevisionClick: () => void;
}

export const useViperAssetEditSchema = ({
  parts,
  manufacturers,
  commsTypes,
  firmwareRevisions,
  handleCreateFirmwareRevisionClick,
  ipAssignmentMethods,
  rootRevisionPasswords,
  createLifecycleOptions,
  customers,
  remoteAccessOptions,
  viperAsset,
  lifeCycleHistory,
  lifeCycleHistoryDetails,
  editing,
  creating,
  getNewLifecycleEvent,
}: GetViperAssetEditSchemaProps) => {
  const { t } = useTranslation('viper-asset');

  const identificationEditSchema = useIdentificationEditSchema({
    parts,
    viperAsset,
    editing: editing === 'identification',
  });

  const lifecycleEditSchema = useLifecycleEditSchema({
    createLifecycleOptions,
    customers,
    viperAsset,
    lifeCycleHistory,
    lifeCycleHistoryDetails,
    editing,
    creating,
    getNewLifecycleEvent,
  });

  const manufacturingEditSchema = useManufacturingEditSchema({
    manufacturers,
    viperAsset,
    editing: editing === 'manufacturing',
  });

  const configurationEditSchema = useConfigurationEditSchema({
    commsTypes,
    firmwareRevisions,
    ipAssignmentMethods,
    rootRevisionPasswords,
    remoteAccessOptions,
    viperAsset,
    handleCreateFirmwareRevisionClick,
    editing: editing === 'configuration',
  });

  const commercialEditSchema = useCommercialEditSchema({
    customers,
    viperAsset,
    editing: editing === 'commercial',
  });

  const generalEditSchema = useGeneralEditSchema({
    viperAsset,
  });

  return {
    fields: [
      {
        name: 'create-viper-asset-wizard',
        component: componentTypes.WIZARD,
        fields: [
          {
            name: 'identification',
            title: t('identification'),
            nextStep: LifecycleEventType.Installation,
            fields: identificationEditSchema.fields,
          },
          {
            name: 'lifecycle',
            title: t('lifecycle'),
            nextStep: 'manufacturing',
            component: 'group',
            highlightColor: colors.lightblue[100],
            fields: lifecycleEditSchema.fields,
          },
          {
            name: 'manufacturing',
            title: t('manufacturing'),
            nextStep: 'configuration',
            fields: manufacturingEditSchema.fields,
          },
          {
            name: 'configuration',
            title: t('configuration'),
            nextStep: 'commercial',
            fields: configurationEditSchema.fields,
          },
          {
            name: 'commercial',
            title: t('commercial'),
            fields: commercialEditSchema.fields,
          },
          {
            name: 'general',
            title: t('general'),
            fields: generalEditSchema.fields,
          },
        ],
      },
    ],
  };
};
