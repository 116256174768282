import { Exact, GetFieldDocument } from '~/__generated__/graphql';
import {
  DocumentNode,
  OperationVariables,
  QueryResult,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

/**
 * A function which has the signature of a useQuery hook, but which never executes
 * the query. Can be used where a useQuery hook is required but there are no values
 * needed.
 */
export function useGetNever<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>() {
  return useQuery(
    GetFieldDocument as DocumentNode | TypedDocumentNode<TData, TVariables>,
    { skip: true } as QueryHookOptions<TData, TVariables>
  ) as QueryResult<TData, Exact<TVariables>>;
}
