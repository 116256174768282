import React from 'react';

import { Table } from '~/components/Table/Table';
import { Box, Typography } from '@mui/material';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { useGetStringReadingsForSerialNumbers } from '~/queries-measurement/measurement';
import { formatDateTime } from '~/utils/date-utils';
import {
  StringMeasurementType,
  ViperAssetPeriodInput,
} from '~/__generated-measurement__/graphql';

// t('message-log/date')
// t('message-log/type')
// t('message-log/message')

// t('message-type/SOFTWAREVERSION')
// t('message-type/MESSAGE')

export const MessagesLog: React.FC<{
  viperAsset: GetViperAssetQuery['viperAsset'];
}> = ({ viperAsset }) => {
  const { t } = useTranslation('viper-asset');

  // Default to blank array if no viper asset is present
  const viperAssetPeriods: ViperAssetPeriodInput[] = viperAsset
    ? [
        {
          serialNumber: viperAsset?.serialNumber ?? '',
          period: {
            start: '1970-01-01',
            end: '2050-01-01',
          },
        },
      ]
    : [];

  // Fetch messages data
  const { data, loading } = useGetStringReadingsForSerialNumbers(viperAssetPeriods, [
    StringMeasurementType.Message,
    StringMeasurementType.Softwareversion,
  ]);

  if (loading) {
    return <></>;
  }

  const messages =
    data?.getStringReadingsForAssets.map((reading) => ({
      _id: reading.time,
      ['message-log/date']: formatDateTime(reading.time),
      ['message-log/type']: t(`message-type/${reading.measurementType}`),
      ['message-log/message']:
        reading.measurementType === StringMeasurementType.Softwareversion
          ? t(`software-version`, { version: reading.value })
          : reading.value,
    })) ?? [];

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('message-logs')}</Typography>
      </Box>
      <Table data={messages} omittedKeys={['_id']} translationKey={'viper-asset'} />
    </>
  );
};
