import {
  Schema,
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import { GetCreateViperAssetOptionsQuery, GetViperAssetQuery } from '~/__generated__/graphql';
import { ipAddress, macAddress } from '../../../../schemas/tools';
import { useTranslation } from 'react-i18next';

interface GetConfigurationEditSchemaProps {
  commsTypes?: GetCreateViperAssetOptionsQuery['commsTypes'];
  firmwareRevisions?: GetCreateViperAssetOptionsQuery['firmwareRevisions'];
  handleCreateFirmwareRevisionClick: () => void;
  ipAssignmentMethods?: GetCreateViperAssetOptionsQuery['ipAssignmentMethods'];
  rootRevisionPasswords?: GetCreateViperAssetOptionsQuery['rootRevisionPasswords'];
  remoteAccessOptions?: { id: boolean; name: string }[];
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useConfigurationEditSchema = ({
  commsTypes,
  firmwareRevisions,
  ipAssignmentMethods,
  rootRevisionPasswords,
  remoteAccessOptions,
  viperAsset,
  handleCreateFirmwareRevisionClick,
}: GetConfigurationEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  return {
    fields: [
      {
        name: 'primaryCommsId',
        label: t('primaryComms'),
        helperText: t('primary-comms-helper'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: commsTypes,
        initialValue: viperAsset?.primaryComms,
      },
      {
        name: 'currentFirmwareRevisionId',
        label: t('currentFirmwareRevision'),
        isClearable: true,
        component: componentTypes.PLAIN_TEXT,
        options: firmwareRevisions,
        initialValue: viperAsset?.currentFirmwareRevision?.name,
        ignoreSave: true,
        isRequiredForReporting: true,
        actionLinks: [
          {
            label: t('update-firmware'),
            action: handleCreateFirmwareRevisionClick,
          },
        ],
      },
      {
        name: 'ipAddress',
        placeholder: 'e.g 123.1.11.0',
        label: t('ipAddress'),
        helperText: t('ip-address-helper'),
        component: componentTypes.TEXT_FIELD,
        initialValue: viperAsset?.ipAddress,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: ipAddress,
            message: t('ip-address-message'),
          },
        ],
      },
      {
        name: 'ipAssignmentMethodId',
        label: t('ipAssignmentMethod'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: ipAssignmentMethods,
        initialValue: viperAsset?.ipAssignmentMethod,
      },
      {
        name: 'macAddress',
        placeholder: 'e.g 5c:aa:12:45:c3:f4',
        label: t('macAddress'),
        sx: { gridColumnEnd: 'span 2' },
        component: componentTypes.TEXT_FIELD,
        initialValue: viperAsset?.macAddress,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: macAddress,
            message: t('mac-address-message'),
          },
        ],
      },
      {
        name: 'adminPassword',
        label: t('adminPassword'),
        component: componentTypes.TEXT_FIELD,
        initialValue: viperAsset?.adminPassword,
      },
      {
        name: 'rootRevisionPasswordId',
        label: t('rootRevisionPassword'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: rootRevisionPasswords,
      },
      {
        name: 'maxIrCap',
        label: t('maxIrCap'),
        isRequiredForReporting: true,
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Ω',
        initialValue: viperAsset?.maxIrCap,
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 1000,
            message: t('max-ir-cap-message'),
          },
          {
            type: validatorTypes.MAX_NUMBER_VALUE,
            includeThreshold: true,
            value: 1000000000,
            message: t('max-ir-cap-message-max'),
          },
        ],
      },
      {
        name: 'remoteAccess',
        label: t('remoteAccess'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: remoteAccessOptions,
        initialValue: viperAsset?.remoteAccess,
      },
    ],
  };
};
