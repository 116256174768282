import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteVLifeReportForm, useApproveVLifeReportForm } from '~/queries/channel';
import { withErrorHandling } from '~/components/Form/SaveError';
import { useApproveVLifeReportFormSchema } from './useApproveVLifeReportFormSchema';
import { ReportGrade } from '~/__generated__/graphql';
import { formDefaults } from '../VLifeReports/panes/formDefaults';

// t('date')
// t('message')

export function useApproveVLifeReportFormHook() {
  const { reportForm, schema, loading } = useApproveVLifeReportFormSchema();

  const { reportFormId } = useParams();
  const [deleteReportForm, deleteError] = withErrorHandling(useDeleteVLifeReportForm());
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();

  // save

  const [showConfirmReview, setShowConfirmReview] = useState<'approve' | 'review'>();
  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState<Record<string, unknown>>({});

  const [approveReportForm, approveReportFormError] = withErrorHandling(
    useApproveVLifeReportForm()
  );

  const onCancel = useCallback(() => {
    // set saving to true so the blocker is disabled
    setSaving(true);
    // allow page to rerender before navigating, so the blocker is actually disabled
    setTimeout(() => navigate(formDefaults.approve.path), 0);
  }, [navigate]);

  const onApprove = () => {
    setShowConfirmReview('approve');
  };

  const onSendForReview = () => {
    setShowConfirmReview('review');
  };

  const handleConfirmReview = useCallback(async () => {
    setSaving(true);
    try {
      if (reportFormId) {
        const result = await approveReportForm({
          variables: {
            id: reportFormId,
            vlifeReportFormApproveInput: {
              sendBackForReview: showConfirmReview === 'review',
              // report grade isn't saved when sending back for review, but must be set in order
              // to satisfy the constraints on the graphql query
              vlifeGrade:
                showConfirmReview === 'review'
                  ? ReportGrade.A
                  : (values.vlifeGrade as ReportGrade),
              comment: values.comment as string,
            },
          },
        });
        if (!result.errors) {
          navigate(formDefaults.approve.path);
        }
      }
    } finally {
      setSaving(false);
      setShowConfirmReview(undefined);
    }
  }, [
    reportFormId,
    approveReportForm,
    showConfirmReview,
    values.vlifeGrade,
    values.comment,
    navigate,
  ]);

  // delete

  const deleteForm = useCallback(() => {
    deleteError.reset();
    setShowDelete(true);
  }, [deleteError]);

  const handleConfirmDelete = useCallback(async () => {
    setShowDelete(false);
    if (reportFormId) {
      setSaving(true);
      const result = await deleteReportForm({ variables: { id: reportFormId } });
      if (result.data?.deleteVLifeReportForm === reportFormId) {
        navigate(formDefaults.review.path);
      } else {
        setSaving(false);
      }
    }
  }, [deleteReportForm, reportFormId, navigate]);

  return {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onApprove,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmReview,
    showConfirmReview,
    handleConfirmReview,
    approveReportFormError,
  };
}
