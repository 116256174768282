import {
  GetCreateLifecycleEventOptionsQuery,
  GetCreateViperAssetOptionsQuery,
  LifecycleEventType,
} from '~/__generated__/graphql';
import { useLifecycleInstallationEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleInstallationEditSchema';
import { useLifecycleCommissioningEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleCommissioningEditSchema';
import { useLifecycleDecommissioningEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleDecommissioningEditSchema';
import { useLifecycleUninstallationEditSchema } from '~/pages/ViperAsset/Overview/schemas/edit/lifecycle/useLifecycleUninstallationEditSchema';

export const useCreateLifecycleSchema = ({
  creating,
  installationTypes,
  decommissionReasons,
  uninstallStatuses,
  people,
  customers,
  channels,
}: {
  creating?: string;
  installationTypes?: GetCreateLifecycleEventOptionsQuery['installationTypes'];
  decommissionReasons?: GetCreateLifecycleEventOptionsQuery['decommissionReasons'];
  uninstallStatuses?: GetCreateLifecycleEventOptionsQuery['uninstallStatuses'];
  people?: GetCreateLifecycleEventOptionsQuery['people'];
  customers?: GetCreateViperAssetOptionsQuery['customers'];
  channels?: GetCreateLifecycleEventOptionsQuery['channels'];
}) => {
  const lifecycleInstallationEditSchema = useLifecycleInstallationEditSchema({
    people,
    customers,
    installationTypes,
    editing: creating === LifecycleEventType.Installation,
    creating: creating === LifecycleEventType.Installation,
    channels,
  });

  const lifecycleCommissioningEditSchema = useLifecycleCommissioningEditSchema({
    people,
    editing: creating === LifecycleEventType.Commissioning,
  });

  const lifecycleDecommissioningEditSchema = useLifecycleDecommissioningEditSchema({
    people,
    decommissionReasons,
    editing: creating === LifecycleEventType.Decommissioning,
  });

  const lifecycleUninstallationEditSchema = useLifecycleUninstallationEditSchema({
    people,
    uninstallStatuses,
    editing: creating === LifecycleEventType.Uninstallation,
  });

  switch (creating) {
    case LifecycleEventType.Installation:
      return lifecycleInstallationEditSchema.fields;
    case LifecycleEventType.Commissioning:
      return lifecycleCommissioningEditSchema.fields;
    case LifecycleEventType.Decommissioning:
      return lifecycleDecommissioningEditSchema.fields;
    case LifecycleEventType.Uninstallation:
      return lifecycleUninstallationEditSchema.fields;
    default:
      return null;
  }
};
