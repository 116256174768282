import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getVLifeConfigStatus } from '~/pages/CustomerAssets/utils';

export const getVLifeStatus = ({ row }: GridRenderCellParams) => {
  const { mostRecentVLifeConfig, upcomingVLifeConfig } = row;
  return getVLifeConfigStatus({
    mostRecentVLifeConfig,
    upcomingVLifeConfig,
  });
  return 1;
};
