import { TFunction } from 'i18next';
import { RowDataType } from '~/components/Table/Table';

import { DefaultRawDatum } from '@nivo/pie';
import { GetDashboardValuesQuery, VLifeStatus } from '~/__generated__/graphql';
import { DateTime } from 'luxon';
import { formatDate } from '~/utils/date-utils';
import { BarDatum } from '@nivo/bar';

interface Item {
  id: string | number;
  name: string | number;
}

export interface RawData {
  [key: string]: Item | number | string;
}

export function mapRawDataToTableData(
  t: TFunction,
  key = 'key',
  value = 'count',
  rawData?: RawData[]
): RowDataType[] | undefined {
  if (!rawData || rawData?.length === 0) {
    return undefined;
  }

  const sumOfValues = rawData.reduce((acc, item) => acc + Number(item[value]), 0);
  return rawData.map(
    (item) =>
      ({
        _id: `${(item[key] as Item).id}`,
        [t(key) ?? key]: `${(item[key] as Item).name}`,
        [t(value) ?? value]: item[value],
        [t('percentage')]: `${((Number(item[value]) / sumOfValues) * 100).toFixed(2)}%`,
      } as RowDataType)
  );
}

export function mapRawDataToPieData(
  data?: RawData[],
  indexBy?: string,
  key?: string,
  length?: number
) {
  if (!data || data?.length === 0 || !indexBy || !key) {
    return undefined;
  }
  return data
    .map((item) => ({
      id: (item[indexBy] as Item).name,
      value: item[key],
    }))
    .slice(0, length) as DefaultRawDatum[];
}

export function mapRawDataToBarData(
  data?: RawData[],
  indexBy?: string,
  value?: string,
  length?: number
): BarDatum[] | undefined {
  if (!data || data?.length === 0 || !indexBy || !value) {
    return undefined;
  }
  return data
    .map((item) => ({
      [indexBy]: (item[indexBy] as Item).name,
      [value]: item[value],
    }))
    .slice(0, length) as BarDatum[];
}

export function getLicenceHistory(
  t: TFunction,
  data: GetDashboardValuesQuery['reports']['vlifeLicenceHistory'] | undefined
) {
  return data?.map((d) => ({
    indexBy: DateTime.fromISO(d.month).toFormat('MMM yy'),
    month: d.month,
    [VLifeStatus.ActiveTrial]:
      d.counts.find((c) => c.status === VLifeStatus.ActiveTrial)?.count ?? 0,
    [VLifeStatus.ActiveFoc]:
      d.counts.find((c) => c.status === VLifeStatus.ActiveFoc)?.count ?? 0,
    [VLifeStatus.ActivePaid]:
      d.counts.find((c) => c.status === VLifeStatus.ActivePaid)?.count ?? 0,
    [VLifeStatus.ActiveNonRefundable]:
      d.counts.find((c) => c.status === VLifeStatus.ActiveNonRefundable)?.count ?? 0,
    activeCount: d.activeCount,
  }));
}

export function getIsoDate(date: Date) {
  return date.toISOString().split('T')[0];
}

export function getStartDateString(monthStart: string, startDate: string, altText: string) {
  return startDate >= monthStart ? formatDate(startDate) : altText;
}

export function getEndDateString(monthEnd: string, endDate: string, altText: string) {
  return endDate <= monthEnd ? formatDate(endDate) : altText;
}

export function mapColorsToKeys(basicColors: string[], keys: string[]) {
  const colors: Record<string, string> = {};
  for (let i = 0; i < keys.length; i++) {
    colors[keys[i]] = basicColors[i % basicColors.length];
  }
  return colors;
}
