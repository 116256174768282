import {
  NegatableNumberListFilters,
  TypedGridFilterItem,
} from '~/components/DataGrid/filters/types';
import { GetApplyFilterFnLegacy, GridCellParams } from '@mui/x-data-grid-pro';

export const getNumberContainsFilter: GetApplyFilterFnLegacy = ({
  value: filterValues,
}: TypedGridFilterItem<NegatableNumberListFilters>) => {
  if (filterValues === undefined) {
    return null;
  }

  const { comparatorValues, isNegated } = filterValues;

  if (!comparatorValues?.length) {
    return null;
  }

  return ({ value }: GridCellParams) => {
    const comparison = comparatorValues.includes(Number(value));
    return isNegated ? !comparison : comparison;
  };
};
