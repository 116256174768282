import { VLifeConfig } from '~/__generated__/graphql';
import { VLifeConfigWithStatus, VLifeHistoricalStatus } from '~/pages/CustomerAssets/types';

type GetVLifeLicencesForChannelProps = {
  upcomingVLifeConfig?: Omit<VLifeConfig, 'channel'> | null;
  mostRecentVLifeConfig?: Omit<VLifeConfig, 'channel'> | null;
  historicalVLifeConfigs?: (Omit<VLifeConfig, 'channel'> | null)[];
};

export const getVLifeConfigsWithStatus = (
  channel: GetVLifeLicencesForChannelProps
): VLifeConfigWithStatus[] => {
  const allVLifeLicences: VLifeConfigWithStatus[] = [];

  if (channel.upcomingVLifeConfig) {
    allVLifeLicences.push({
      status: VLifeHistoricalStatus.UPCOMING,
      ...channel.upcomingVLifeConfig,
    });
  }

  if (channel.mostRecentVLifeConfig) {
    allVLifeLicences.push({
      status: VLifeHistoricalStatus.CURRENT,
      ...channel.mostRecentVLifeConfig,
    });
  }

  if (channel.historicalVLifeConfigs) {
    channel.historicalVLifeConfigs
      .filter((config) => config?.id !== channel.mostRecentVLifeConfig?.id)
      .forEach((config) => {
        if (config) {
          allVLifeLicences.push({
            status: VLifeHistoricalStatus.HISTORICAL,
            ...config,
          });
        }
      });
  }

  return allVLifeLicences;
};
