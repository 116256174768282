import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { GetChannelQuery, GetCreateChannelOptionsQuery } from '~/__generated__/graphql';
import { getDerivedValue } from '../../tools';

interface GetSystemInformationEditSchemaProps {
  t: TFunction;
  platforms?: GetCreateChannelOptionsQuery['platforms'];
  productionControlSystems?: GetCreateChannelOptionsQuery['productionControlSystems'];
  umbilicals?: GetCreateChannelOptionsQuery['umbilicals'];
  channel?: GetChannelQuery['channel'];
  onAddPlatform: SelectActionHandler;
  onAddUmbilical: SelectActionHandler;
  onAddProductionControlSystem?: SelectActionHandler;
  onEditPlatform: SelectActionHandler;
  onEditUmbilical: SelectActionHandler;
  onEditProductionControlSystem?: SelectActionHandler;
}

export function getSystemInformationEditSchema({
  t,
  platforms,
  productionControlSystems,
  umbilicals,
  channel,
  onAddPlatform,
  onAddUmbilical,
  onAddProductionControlSystem,
  onEditPlatform,
  onEditUmbilical,
  onEditProductionControlSystem,
}: GetSystemInformationEditSchemaProps): Schema {
  return {
    fields: [
      {
        name: 'platform',
        label: t('customer-asset-forms::platform'),
        sx: { gridColumnEnd: 'span 2' },
        isClearable: true,
        isRequiredForReporting: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        options: platforms,
        initialValue: channel?.platform,
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddPlatform,
          },
          {
            label: t('viper::actions/edit'),
            action: onEditPlatform,
            disabled: (value: string) => !value,
          },
        ],
      },
      {
        name: 'productionControlSystemId',
        label: t('customer-asset-forms::production-control-system'),
        isClearable: true,
        component: componentTypes.SELECT,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: channel?.productionControlSystem,
        resolveProps: (_props, _fieldApi, formOptions) => {
          const platformId = formOptions.getState().values['platform'];
          return {
            isDisabled: !platformId,
            options: productionControlSystems
              ?.filter((pcs) => pcs.platform.id === platformId)
              .map((pcs) => ({
                id: pcs.id,
                name: getDerivedValue(pcs, ['epuManufacturer.name', 'pcsManufacturer.name']),
              })),
          };
        },
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddProductionControlSystem,
            disabled: (value: string | undefined, values: Record<string, unknown>) =>
              !values['platform'],
          },
          {
            label: t('viper::actions/edit'),
            action: onEditProductionControlSystem,
            disabled: (value: string) => !value,
          },
        ],
      },
      {
        name: 'umbilical',
        label: t('customer-asset-forms::umbilical'),
        isClearable: true,
        sortAlphabetical: true,
        isSearchable: true,
        component: componentTypes.SELECT,
        initialValue: channel?.umbilical,
        resolveProps: (_props, _fieldApi, formOptions) => {
          const platformId = formOptions.getState().values['platform'];
          return {
            isDisabled: !platformId,
            options: umbilicals
              ?.filter((umbilical) => umbilical.platform.id === platformId)
              .map((umbilical) => ({
                id: umbilical.id,
                name: `${getDerivedValue(umbilical, ['manufacturer.name', 'length'])} km`,
              })),
          };
        },
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddUmbilical,
            disabled: (value: string | undefined, values: Record<string, unknown>) =>
              !values['platform'],
          },
          {
            label: t('viper::actions/edit'),
            action: onEditUmbilical,
            disabled: (value: string) => !value,
          },
        ],
      },
    ],
  };
}
