import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';
import { GetCreateChannelOptionsQuery } from '~/__generated__/graphql';

interface GetIdentificationCreateSchemaProps {
  t: TFunction;
  fields?: GetCreateChannelOptionsQuery['fields'];
  customers?: GetCreateChannelOptionsQuery['customers'];
  onAddField: SelectActionHandler;
}

export function getIdentificationCreateSchema({
  t,
  fields,
  customers,
  onAddField,
}: GetIdentificationCreateSchemaProps) {
  return {
    fields: [
      {
        name: 'name',
        label: t('customer-asset-forms::channel-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        component: componentTypes.SELECT,
        name: 'fieldId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::field'),
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: fields,
        actionLinks: [
          {
            label: t('viper::actions/add'),
            action: onAddField,
          },
        ],
      },
      {
        name: 'country',
        label: t('customer-asset-forms::country'),
        component: componentTypes.PLAIN_TEXT,
        condition: { when: 'fieldId', isNotEmpty: true },
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.country?.name,
          };
        },
      },
      {
        name: 'continent',
        label: t('customer-asset-forms::continent'),
        component: componentTypes.PLAIN_TEXT,
        condition: { when: 'fieldId', isNotEmpty: true },
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.country?.continent.name,
          };
        },
      },
      {
        component: componentTypes.SELECT,
        name: 'customerId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('customer-asset-forms::customer'),
        helperText: t('customer-asset-forms::customer-helper'),
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: customers,
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.defaultCustomer,
          };
        },
      },
      {
        component: componentTypes.SELECT,
        sortAlphabetical: true,
        isSearchable: true,
        name: 'operatorId',
        label: t('customer-asset-forms::operator'),
        helperText: t('customer-asset-forms::operator-helper'),
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: customers,
        resolveProps: (
          _props: Record<string, unknown>,
          _field: Record<string, unknown>,
          formOptions: { getState: () => { values: { fieldId: string } } }
        ) => {
          const selectedFieldId = formOptions.getState().values.fieldId;
          const selectedField = fields?.find((field) => field.id === selectedFieldId);
          return {
            initialValue: selectedField?.defaultOperator,
          };
        },
      },
      {
        name: 'viperProjectNumber',
        label: t('customer-asset-forms::viper-project-number'),
        sx: { gridColumnEnd: 'span 2' },
        component: componentTypes.TEXT_FIELD,
      },
    ],
  };
}
