import { InstalledViperAssetPeriod, VLifeConfig } from '~/__generated__/graphql';
import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';

export enum VLifeHistoricalStatus {
  UPCOMING = 'Upcoming',
  CURRENT = 'Current',
  HISTORICAL = 'Historical',
  UNLICENCED = 'Unlicenced',
}

export enum VLifeStatus {
  NEVER_SET_UP = -1,
  TRIAL = 0,
  FREE_OF_CHARGE = 1,
  PAID = 2,
  NON_REFUNDABLE = 3,
  SUSPENDED = 4,
  DEACTIVATED = 5,
  INACTIVE = 6,
}

export interface VLifeConfigWithStatus extends Omit<VLifeConfig, 'channel'> {
  status: VLifeHistoricalStatus;
}

export interface CustomerAssetRow {
  id: string;
  'field.defaultOperator.name': string;
  'field.country.name': string;
  viperProjectNumber: string;
  'platform.name': string;
  'platform.waterDepth': number;
  'productionControlSystem.pcsManufacturer.name': string;
  'productionControlSystem.pcsApproxInstallationDate': string;
  'productionControlSystem.epuManufacturer.name': string;
  'productionControlSystem.epuApproxInstallationDate': string;
  'productionControlSystem.frequency': number;
  'productionControlSystem.maximumOperatingVoltage': number;
  'productionControlSystem.electricalDistributionType.name': string;
  'productionControlSystem.electricalTopology.name': string;
  'umbilical.manufacturer.name': string;
  'umbilical.materialType.name': string;
  'umbilical.approxInstallationDate': string;
  'umbilical.length': string;
  operatingVoltage: number;
  referenceIR: number;
  'couplingType.name': string;
  alarm1Value: number;
  alarm2Value: number;
  'tripAlarmRelay.name': string;
  'relayConfiguration.name': string;
  couplerUsed: string;
  'interfaceCard.name': string;
  'poweringDevice.name': string;
  negativeImpactOnRebootNotes: string;
  latestFileReceived: string;
  dataDue: string;
  notes: string;
  'upcomingVLifeConfig.startDate': string;
  'mostRecentVLifeConfig.startDate': string;
  'mostRecentVLifeConfig.isExpired': boolean;
  'mostRecentVLifeConfig.isDisabled': boolean;
  'mostRecentVLifeConfig.deactivationReason.name': string;
  'mostRecentVLifeConfig.vlifeLicence.vlifeLicenceType.id': string;
  mostRecentVLifeConfig?: VLifeConfig;
  upcomingVLifeConfig?: VLifeConfig;
  historicalVLifeConfigs: VLifeConfig[];
  vLifeLicences: VLifeStatusWindow[];
  installedViperAssetPeriods: InstalledViperAssetPeriod[];
}

interface EnrichmentData {
  latestIRValue: number | undefined;
  percentageIRChange: number | undefined;
}

export interface EnrichedCustomerAssetRow extends CustomerAssetRow, EnrichmentData {}
