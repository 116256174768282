import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { useRouteId } from '~/common/useRouteId';
import { useGetStringReadingsForSerialNumbers } from '~/queries-measurement/measurement';
import { formatDate } from '~/utils/date-utils';
import { formatPerson } from '~/utils/format-person';
import { StringMeasurementType } from '~/__generated-measurement__/graphql';

export function useFirmwareLog(viperAsset: GetViperAssetQuery['viperAsset']) {
  const { t } = useTranslation('viper-asset');
  const id = useRouteId();

  const [showFirmwareLogDialog, setShowFirmwareLogDialog] = useState<string | undefined>();
  const [showCreateFirmwareLogDialog, setShowCreateFirmwareLogDialog] = useState(false);

  const { data: firmwareUpdateReadings, loading } = useGetStringReadingsForSerialNumbers(
    [
      {
        serialNumber: viperAsset?.serialNumber ?? '',
        period: {
          start: '1970-01-01',
          end: '2050-01-01',
        },
      },
    ],
    [StringMeasurementType.Softwareversion]
  );

  const handleFirmwareLogActionClick = (
    menuItemId: string,
    rowIndex: number,
    row: (Record<string, unknown> & { _id: string }) | undefined
  ) => {
    if (menuItemId === 'edit') {
      setShowFirmwareLogDialog(row?._id);
    }
  };

  const assetFirmwareLogs = useMemo(() => {
    return (
      viperAsset?.firmwareUpdates?.map((log, index, logs) => ({
        _id: log.id,
        [t('viper-asset::firmware/date')]: {
          label: formatDate(log.dateOfUpdate),
          value: log.dateOfUpdate,
        },
        [t('firmware/person')]: formatPerson(log.person),
        [t('firmware/update-from')]: logs[index + 1]?.firmwareRevision.name,
        [t('firmware/update-to')]: log.firmwareRevision.name,
      })) ?? []
    );
  }, [t, viperAsset?.firmwareUpdates]);

  const readingsFirmwareLogs = useMemo(() => {
    return (
      firmwareUpdateReadings?.getStringReadingsForAssets.map((reading) => ({
        _id: reading.time,
        [t('viper-asset::firmware/date')]: {
          label: formatDate(reading.time),
          value: reading.time,
        },
        [t('firmware/person')]: t('firmware/v-log-file'),
        [t('firmware/update-to')]: reading.value,
        _actions: false,
      })) ?? []
    );
  }, [firmwareUpdateReadings?.getStringReadingsForAssets, t]);

  const firmwareLogs = useMemo(() => {
    return [...assetFirmwareLogs, ...readingsFirmwareLogs];
  }, [assetFirmwareLogs, readingsFirmwareLogs]);

  const firmwareLogsDetails = useMemo(() => {
    return (
      viperAsset?.firmwareUpdates?.map((log) => ({
        _id: log.id,
        [t('firmware/comments')]: log.comment,
      })) ?? []
    );
  }, [t, viperAsset?.firmwareUpdates]);

  return {
    handleFirmwareLogActionClick,
    showFirmwareLogDialog,
    setShowFirmwareLogDialog,
    firmwareLogs,
    firmwareLogsDetails: firmwareLogsDetails as {
      [x: string]: string | undefined;
      _id: string;
    }[],
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    id,
    loading,
  };
}
