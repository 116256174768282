import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { DateRange } from '~/common/types';
import { GainAttritionStatistics } from '~/pages/Dashboard/gain-attrition/types';

export const getGainAttritionStatusWindowsWithinDateRange = (
  vLifeStatusWindows: VLifeStatusWindow[],
  dateRange: DateRange
): VLifeStatusWindow[] => {
  return (
    vLifeStatusWindows
      // Filter out any without gains or attrition
      .filter(({ gain, attrition }) => {
        return (gain && gain === true) || (attrition && attrition === true);
      })
      // Filter out any that don't overlap with the date range
      .filter(({ startDate, endDate }) => {
        return (
          (!startDate || startDate.getTime() <= dateRange.endDate.getTime()) &&
          (!endDate || endDate.getTime() >= dateRange.startDate.getTime())
        );
      })
  );
};
/*
  Returns the gain / attrition totals for the current date range.
  Gains increase if a paid licence with "gain" starts within the date range.
  Attrition increases if a paid licence with "attrition" ends within the date range.
 */
export const getGainAttritionTotalsForDateRange = (
  vLifeStatusWindows: VLifeStatusWindow[],
  dateRange: DateRange
): GainAttritionStatistics => {
  // Filter out StatusWindows with no gains / attrition OR not overlapping with date range
  const includedWindows = getGainAttritionStatusWindowsWithinDateRange(
    vLifeStatusWindows,
    dateRange
  );

  // Reduce all remaining windows, counting gains and attrition
  const { gainsTotal, attritionTotal } = includedWindows.reduce(
    (
      { gainsTotal, attritionTotal },
      { startDate, endDate, gain, attrition }: VLifeStatusWindow
    ) => {
      // Only a gain if the licence starts in the current month
      const addGain =
        gain &&
        startDate &&
        dateRange.startDate <= startDate &&
        startDate <= dateRange.endDate;
      // Only add to attrition if the licence ends in the current month
      const addAttrition =
        attrition && endDate && dateRange.startDate <= endDate && endDate <= dateRange.endDate;
      return {
        gainsTotal: gainsTotal + (addGain ? 1 : 0),
        attritionTotal: attritionTotal + (addAttrition ? 1 : 0),
      };
    },
    {
      gainsTotal: 0,
      attritionTotal: 0,
    }
  );

  return {
    dateRange,
    gains: gainsTotal,
    attrition: attritionTotal,
  };
};
