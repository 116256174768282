import { useTranslation } from 'react-i18next';
import { VLifeStatus } from '~/pages/CustomerAssets/types';
import colors from '~/themes/colors';

interface VLifeStatusMetadata {
  label: string;
  color: string;
}

export const useGetVLifeStatusMetadata = (status: number): VLifeStatusMetadata => {
  const { t } = useTranslation('customer-assets');

  switch (status) {
    case VLifeStatus.TRIAL:
      return {
        label: t('trial'),
        color: colors.yellow[500],
      };
    case VLifeStatus.FREE_OF_CHARGE:
      return {
        label: t('free-of-charge'),
        color: colors.blue[500],
      };
    case VLifeStatus.NON_REFUNDABLE:
      return {
        label: t('non-refundable'),
        color: colors.purple[500],
      };
    case VLifeStatus.PAID:
      return {
        label: t('paid'),
        color: colors.green[500],
      };
    case VLifeStatus.SUSPENDED:
      return {
        label: t('suspended'),
        color: colors.orange[500],
      };
    case VLifeStatus.DEACTIVATED:
      return {
        label: t('deactivated'),
        color: colors.grey[500],
      };
    case VLifeStatus.INACTIVE:
      return {
        label: t('expired'),
        color: colors.red[500],
      };
    case VLifeStatus.NEVER_SET_UP:
      return {
        label: t('unlicenced'),
        color: colors.darkblue[500],
      };
    default:
      return {
        label: t('unknown-status'),
        color: colors.grey[500],
      };
  }
};
