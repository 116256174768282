import { useGetChannelsWithVLifeConfigs } from '~/queries/channel';
import { generateVLifeTimeline } from '~/pages/CustomerAssets/custom-row-fields/generateVLifeTimeline';
import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';

export interface ChannelWithVLifeTimeline {
  channelId: string;
  channelName: string;
  customerName: string;
  platformName?: string | null;
  fieldName: string;
  vLifeTimeline: VLifeStatusWindow[];
}

interface UseGetChannelsWithVLifeTimelinesResponse {
  data: ChannelWithVLifeTimeline[];
  loading: boolean;
}

export const useGetChannelsWithVLifeTimelines =
  (): UseGetChannelsWithVLifeTimelinesResponse => {
    const { data: channelVLifeConfigs, loading } = useGetChannelsWithVLifeConfigs();

    const data =
      channelVLifeConfigs?.channels.map(
        ({
          upcomingVLifeConfig,
          historicalVLifeConfigs,
          mostRecentVLifeConfig,
          ...channel
        }) => ({
          channelId: channel.id,
          channelName: channel.name,
          customerName: channel.customer.name,
          platformName: channel.platform?.name,
          fieldName: channel.field.name,
          vLifeTimeline: generateVLifeTimeline({
            upcomingVLifeConfig,
            historicalVLifeConfigs,
            mostRecentVLifeConfig,
          }),
        })
      ) ?? [];

    return {
      data,
      loading,
    };
  };
