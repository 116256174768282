import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetContactQuery, GetCreateContactOptionsQuery } from '~/__generated__/graphql';

interface GetContactEditSchemaProps {
  t: TFunction;
  contact?: GetContactQuery['person'] | null;
  options?: GetCreateContactOptionsQuery | null;
  editing?: string;
}

/**
 * This schema also serves as the create schema returned by getContactCreateSchema
 */
export function getContactEditSchema({
  t,
  options,
  contact,
}: GetContactEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'forename',
        label: t('contact-forms::first-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        initialValue: contact?.forename,
      },
      {
        name: 'surname',
        label: t('contact-forms::last-name'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        initialValue: contact?.surname,
      },
      {
        name: 'position',
        label: t('contact-forms::position'),
        component: componentTypes.TEXT_FIELD,
        initialValue: contact?.position,
      },
      {
        name: 'companyId',
        label: t('contact-forms::company'),
        isSearchable: true,
        sortAlphabetical: true,
        component: componentTypes.SELECT,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: options?.customers,
        initialValue: contact?.company,
      },
      {
        name: 'primaryTelephone',
        label: t('contact-forms::primary-telephone'),
        component: componentTypes.TEXT_FIELD,
        initialValue: contact?.primaryTelephone,
      },
      {
        name: 'secondaryTelephone',
        label: t('contact-forms::secondary-telephone'),
        component: componentTypes.TEXT_FIELD,
        initialValue: contact?.secondaryTelephone,
      },
      {
        name: 'email',
        label: t('contact-forms::email'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        validate: [
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
          {
            type: validatorTypes.PATTERN,
            pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
            message: t('viper::invalid-email'),
          },
        ],
        sx: { gridColumnEnd: 'span 2' },
        initialValue: contact?.email,
      },
    ],
  };
  return createSchema;
}
