import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';

export const filterStatusWindowsOutsideFilterDateRange = (
  statusWindows: VLifeStatusWindow[],
  startDate: Date,
  endDate: Date
) => {
  return statusWindows.filter((config) => {
    const { startDate: windowStartDate, endDate: windowEndDate } = config;
    // Licence falls outside of period, filter out
    return !(
      (windowEndDate && windowEndDate < startDate) ||
      (windowStartDate && windowStartDate > endDate)
    );
  });
};
