import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetViperAssetQuery } from '~/__generated__/graphql';
import { useRouteId } from '~/common/useRouteId';
import { formatDate } from '~/utils/date-utils';
import { formatPerson } from '~/utils/format-person';

export const useMaintenanceLog = (viperAsset: GetViperAssetQuery['viperAsset']) => {
  const { t } = useTranslation('viper-asset');
  const id = useRouteId();

  const [showCreateMaintenanceLogDialog, setShowCreateMaintenanceLogDialog] = useState(false);
  const [showMaintenanceLogDialog, setShowMaintenanceLogDialog] = useState<
    string | undefined
  >();

  const handleMaintenanceLogActionClick = (
    menuItemId: string,
    rowIndex: number,
    row: (Record<string, unknown> & { _id: string }) | undefined
  ) => {
    if (menuItemId === 'edit') {
      setShowMaintenanceLogDialog(row?._id);
    }
  };

  const maintenanceLogs = useMemo(() => {
    return (
      viperAsset?.maintenanceLogs?.map((log) => ({
        _id: log.id,
        [t('maintenance/start-date')]: formatDate(log.maintenanceStartDate),
        [t('maintenance/end-date')]: formatDate(log.maintenanceEndDate),
        [t('maintenance/type')]: log.maintenanceType.name,
        [t('maintenance/person')]: formatPerson(log.person),
        [t('maintenance/company')]: log.person?.company?.name,
      })) ?? []
    );
  }, [t, viperAsset?.maintenanceLogs]);

  const maintenanceLogsDetails = useMemo(() => {
    return (
      viperAsset?.maintenanceLogs?.map((log) => ({
        _id: log.id,
        [t('maintenance/outcome')]: log.outcomeOfMaintenance,
      })) ?? []
    );
  }, [t, viperAsset?.maintenanceLogs]);

  return {
    showCreateMaintenanceLogDialog,
    setShowCreateMaintenanceLogDialog,
    showMaintenanceLogDialog,
    setShowMaintenanceLogDialog,
    handleMaintenanceLogActionClick,
    maintenanceLogs,
    maintenanceLogsDetails,
    id,
  };
};
