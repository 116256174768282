import {
  Schema,
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetCreateUmbilicalOptionsQuery, GetUmbilicalQuery } from '~/__generated__/graphql';
import { getRecentYears } from '~/utils/date-utils';

interface GetUmbilicalEditSchemaProps {
  t: TFunction;
  manufacturers?: GetCreateUmbilicalOptionsQuery['manufacturers'];
  materialTypes?: GetCreateUmbilicalOptionsQuery['materialTypes'];
  platformName?: string;
  umbilical?: GetUmbilicalQuery['umbilical'] | null;
  editing?: string;
}

/**
 * This schema also serves as the create schema returned by getPlatformCreateSchema
 */
export function getUmbilicalEditSchema({
  t,
  manufacturers,
  platformName,
  materialTypes,
  umbilical,
}: GetUmbilicalEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'manufacturerId',
        label: t('umbilical-forms::manufacturer'),
        component: componentTypes.SELECT,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: umbilical?.manufacturer,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: manufacturers,
      },
      {
        name: 'length',
        label: t('umbilical-forms::length/label'),
        component: componentTypes.TEXT_FIELD,
        initialValue: umbilical?.length,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'km',
        isRequired: true,
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('umbilical-forms::length/message'),
          },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
      },
      {
        name: 'materialTypeId',
        label: t('umbilical-forms::material-type'),
        component: componentTypes.SELECT,
        isClearable: true,
        initialValue: umbilical?.materialType,
        options: materialTypes,
      },
      {
        name: 'approxInstallationDate',
        component: componentTypes.SELECT,
        dataType: dataTypes.INTEGER,
        options: getRecentYears(),
        initialValue: {
          id: umbilical?.approxInstallationDate,
          value: umbilical?.approxInstallationDate,
        },
        label: t('umbilical-forms::approximate-installation-date'),
      },

      {
        name: 'platform',
        label: t('umbilical-forms::platform'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: platformName,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
  return createSchema;
}
