import { StyledInput } from '~/components/Table/components/CustomInput';
import { Box, MenuItem, Select, SelectProps } from '@mui/material';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';
import React from 'react';
import { VLifeStatus } from '~/pages/CustomerAssets/types';

export const VLifeStatusSelect = (props: SelectProps<number[]>) => {
  const allVLifeStatuses = [
    VLifeStatus.NEVER_SET_UP,
    VLifeStatus.TRIAL,
    VLifeStatus.FREE_OF_CHARGE,
    VLifeStatus.PAID,
    VLifeStatus.NON_REFUNDABLE,
    VLifeStatus.SUSPENDED,
    VLifeStatus.DEACTIVATED,
    VLifeStatus.INACTIVE,
  ];

  return (
    <Select
      {...props}
      aria-label={props.label ? `${props.label}` : 'v-life-select'}
      aria-placeholder={props.placeholder ?? 'v-life-select'}
      value={props.multiple ? props.value ?? [] : props.value}
      displayEmpty
      native={false}
      input={<StyledInput />}
      style={{ marginRight: '8px', flex: '0 0.5 50%' }}
      renderValue={(selected: number[]) =>
        !selected?.length ? (
          <em>{props.placeholder}</em>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
            {selected.map((status) => (
              <StatusPill key={status} status={status} />
            ))}
          </Box>
        )
      }
    >
      {allVLifeStatuses.map((status) => (
        <MenuItem key={status} value={status}>
          <StatusPill key={status} status={status} />
        </MenuItem>
      ))}
    </Select>
  );
};
