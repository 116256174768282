import { Path, PathValue, buildPath } from '~/routes/paths';
import { VLifeReportFormState } from '~/__generated__/graphql';

export const formDefaults: Record<
  'review' | 'approve' | 'publish' | string,
  { path: string; to: PathValue; label: string; state: VLifeReportFormState }
> = {
  review: {
    path: buildPath(Path.COMMON_V_LIFE_REPORTS, { formStatus: 'review' }),
    to: Path.REVIEW_V_LIFE_REPORT,
    label: 'review',
    state: VLifeReportFormState.Created,
  },
  approve: {
    path: buildPath(Path.COMMON_V_LIFE_REPORTS, { formStatus: 'approve' }),
    to: buildPath(Path.APPROVE_V_LIFE_REPORT) as PathValue,
    label: 'approve',
    state: VLifeReportFormState.Reviewed,
  },
  publish: {
    path: buildPath(Path.COMMON_V_LIFE_REPORTS, { formStatus: 'publish' }),
    to: buildPath(Path.PUBLISH_V_LIFE_REPORT) as PathValue,
    label: 'publish',
    state: VLifeReportFormState.Approved,
  },
};
