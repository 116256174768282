import { useGetChannelsWithVLifeTimelines } from '~/pages/Dashboard/useGetChannelsWithVLifeTimelines';
import { useMemo } from 'react';
import { LicenceInsightsRow } from '~/pages/LicenceInsights/types';
import { flattenVLifeTimelineRows } from '~/pages/LicenceInsights/flattenVLifeTimelineRows';

interface UseGetLicenceInsightsRowsResponse {
  rows: LicenceInsightsRow[];
  loading: boolean;
}

export const useLicenceInsightsRows = (): UseGetLicenceInsightsRowsResponse => {
  // Fetch channels with timelines attached
  const { data: channelsWithVLifeTimelines, loading } = useGetChannelsWithVLifeTimelines();

  // Flatten out
  const rows: LicenceInsightsRow[] = useMemo(
    () => channelsWithVLifeTimelines.reduce(flattenVLifeTimelineRows, []),
    [channelsWithVLifeTimelines]
  );

  return {
    rows,
    loading,
  };
};
