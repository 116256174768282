import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useGetVoltageAndIRForSerialNumbers } from '~/queries-measurement/measurement';
import { useGetInstalledViperAssetPeriodAndMaxIRCaps } from '~/queries/channel';

export function useCustomerAssetGraph(channelId?: string) {
  const [yAxisScale, setYAxisScale] = useState<'logarithmic' | 'linear'>('logarithmic');
  const [period, setPeriod] = useState<'allTime' | 'threeMonths' | 'oneMonth' | 'twoWeeks'>(
    'threeMonths'
  );

  const endDate = DateTime.local().toISODate();
  const startDate = useCallback(() => {
    switch (period) {
      case 'threeMonths':
        return DateTime.local().minus({ months: 3 }).toISODate();
      case 'oneMonth':
        return DateTime.local().minus({ months: 1 }).toISODate();
      case 'twoWeeks':
        return DateTime.local().minus({ weeks: 2 }).toISODate();
      default:
        return '1970-01-01';
    }
  }, [period]);

  const {
    loading: loadingViperAssets,
    viperAssetPeriods,
    viperAssetMaxIRCaps,
  } = useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate(), endDate, false);

  const {
    data: voltageAndIRReadings,
    loading: readingsLoading,
    error,
  } = useGetVoltageAndIRForSerialNumbers(viperAssetPeriods, viperAssetMaxIRCaps, 8000);

  const handlePeriodChange = (period: 'allTime' | 'threeMonths' | 'oneMonth' | 'twoWeeks') => {
    setPeriod(period);
  };

  const maxIrCapError = !!error?.graphQLErrors.find(
    (error) => error.extensions['errorCode'] == 'NO_INFINITY_VALUE'
  );

  return {
    handlePeriodChange,
    yAxisScale,
    setYAxisScale,
    loadingViperAssets,
    loading: readingsLoading || loadingViperAssets,
    period,
    csv: voltageAndIRReadings?.getFloatReadingsForAssets.csv,
    maxIrCapError,
    error,
  };
}
