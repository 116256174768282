import {
  CreateViperAssetDocument,
  GetCreateViperAssetOptionsDocument,
  GetCreateLifecycleEventOptionsDocument,
  GetViperAssetDocument,
  GetViperAssetsDocument,
  CreateLifecycleEventDocument,
  UpdateLifecycleEventDocument,
  UpdateViperAssetDocument,
  GetMaintenanceLogOptionsDocument,
  CreateMaintenanceLogDocument,
  UpdateMaintenanceLogDocument,
  GetFirmwareLogOptionsDocument,
  UpdateFirmwareLogDocument,
  CreateFirmwareLogDocument,
  GetFirmwareRevisionsDocument,
} from '~/__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';

export function useGetViperAssets() {
  return useQuery(GetViperAssetsDocument);
}

export function useGetViperAsset(id: string) {
  return useQuery(GetViperAssetDocument, { variables: { id } });
}

export function useCreateViperAsset() {
  return useMutation(CreateViperAssetDocument);
}

export function useGetCreateViperAssetOptions(skip?: boolean) {
  return useQuery(GetCreateViperAssetOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip,
  });
}

export function useGetCreateLifecycleEventOptions(skip?: boolean) {
  return useQuery(GetCreateLifecycleEventOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip,
  });
}

export function useCreateLifecycleEvent() {
  return useMutation(CreateLifecycleEventDocument);
}

export function useUpdateLifecycleEvent() {
  return useMutation(UpdateLifecycleEventDocument);
}

export function useUpdateViperAsset() {
  return useMutation(UpdateViperAssetDocument);
}

export function useGetMaintenanceLogOptions(options?: { skip?: boolean }) {
  return useQuery(GetMaintenanceLogOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetFirmwareLogOptions(options?: { skip?: boolean }) {
  return useQuery(GetFirmwareLogOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetFirmwareRevisions(options?: { skip?: boolean }) {
  return useQuery(GetFirmwareRevisionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useCreateMaintenanceLog(options: { id?: string } | undefined) {
  return useMutation(CreateMaintenanceLogDocument, {
    refetchQueries: [{ query: GetViperAssetDocument, variables: { id: options?.id } }],
  });
}

export function useEditMaintenanceLog(options: { id?: string } | undefined) {
  return useMutation(UpdateMaintenanceLogDocument, {
    refetchQueries: [{ query: GetViperAssetDocument, variables: { id: options?.id } }],
  });
}

export function useEditFirmwareLog(options: { id?: string } | undefined) {
  return useMutation(UpdateFirmwareLogDocument, {
    refetchQueries: [{ query: GetViperAssetDocument, variables: { id: options?.id } }],
  });
}

export function useCreateFirmwareLog(options: { id?: string } | undefined) {
  return useMutation(CreateFirmwareLogDocument, {
    refetchQueries: [{ query: GetViperAssetDocument, variables: { id: options?.id } }],
  });
}
