import { useMemo, useState } from 'react';
import { useGetVLifeReportFormsForState } from '~/queries/channel';
import { useParams } from 'react-router-dom';
import { formDefaults } from './formDefaults';
import { dataGridRowMapper } from '~/components/DataGrid/utils';
import { useVLifeReportFormColumns } from './useVLifeReportFormColumns';
import { DataGridMasterDetailField } from '~/components/DataGridMasterDetail';
import { GridColDef } from '@mui/x-data-grid-pro';

export const useVLifeReportForms = () => {
  const { formStatus } = useParams();
  const [commentsDialogOpen, setCommentsDialogOpen] = useState<string | undefined>(undefined);

  const { data, loading } = useGetVLifeReportFormsForState(
    formStatus ? formDefaults[formStatus].state : undefined
  );

  const { columns, nestedColumns, displayModel } =
    useVLifeReportFormColumns(setCommentsDialogOpen);
  const rows = useMemo(() => {
    return (
      data?.vlifeReportForms.map(
        dataGridRowMapper([...columns, ...(nestedColumns as GridColDef[])])
      ) ?? []
    );
  }, [columns, data?.vlifeReportForms, nestedColumns]);

  const masterDetailFields: DataGridMasterDetailField[] = useMemo(
    () =>
      nestedColumns.map(({ valueMapper, headerName, field }) => ({
        formattedName: headerName ?? '',
        fieldName: field ?? '',
        valueMapper,
      })),
    [nestedColumns]
  );

  return {
    columns,
    masterDetailFields,
    rows,
    loading,
    commentsDialogOpen,
    onCloseCommentsDialog: () => setCommentsDialogOpen(undefined),
    displayModel,
  };
};
