import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../CreateEditEntityDialog/CreateEditEntityDialog';
import { getFirmwareLogCreateSchema } from '~/pages/schemas/create/firmwareLog/firmwareLogCreateSchema';
import { useCreateFirmwareLog, useGetFirmwareLogOptions } from '~/queries/viper-asset';
import { formatDate } from '~/utils/date-utils';
import { GetViperAssetQuery } from '~/__generated__/graphql';

export const CreateFirmwareLogDialog: React.FC<{
  id: string;
  open: boolean;
  firmwareLogs?: NonNullable<GetViperAssetQuery['viperAsset']>['firmwareUpdates'];
  onClose: () => void;
}> = ({ open, onClose, id, firmwareLogs }) => {
  const { t } = useTranslation('viper-asset');

  const lastFirmwareRevision = firmwareLogs?.[0];

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-firmware-log')}
      errorMessage={t(`error-creating-firmware-log`)}
      successMessage={(log) => t('firmware-log-created-successfully', { log })}
      useGetOptions={useGetFirmwareLogOptions}
      useMutate={useCreateFirmwareLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getFirmwareLogCreateSchema({
          t,
          options,
          previousFirmwareRevision: lastFirmwareRevision,
        }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            firmwareUpdateInput: {
              viperAssetId: id,
              firmwareRevisionId: values.firmwareRevisionId as string,
              dateOfUpdate: values.dateOfUpdate as string,
              comment: values.comment as string,
              personId: values.personId as string,
            },
          },
        });
        return formatDate(values.firmwareEndDate as string) as string;
      }}
    />
  );
};
